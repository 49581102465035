import React from "react";
import { Route,Routes,Navigate } from "react-router-dom";
import Home from "./Pages/Home";
import AcademicCalendar from "./Pages/AcademicCalendar";
import GradingandAcademicProgress from "./Pages/GradingandAcademicProgress";
import Programs from "./Pages/Programs";
import GraduationRequirments from "./Pages/GraduationRequirments";
import StudentServices from "./Pages/StudentServices";
import MasterForm from "./Pages/Masters";
import ContactUs from "./Pages/ContactUs";
import AdministrativePolicies from "./Pages/AdministrativePolicies";
import AdmissionPolicies from "./Pages/AdmissionPolicies";
import AdmissionsRequirments from "./Pages/AdmissionsRequirments";
import EnrollementandRegisteration from "./Pages/EnrollementandRegisteration";
import InternationalStudents from "./Pages/InternationalStudents";
import FinancialAssistance from "./Pages/FinancialAssistance";
import TuitionFees from "./Pages/TuitionFees";
import StudentConduct from "./Pages/StudentConduct";
import Statements from "./Pages/Statements";
import LearningPlatform from "./Pages/LearningPlatform";
import TuitionRefundPolicy from "./Pages/TuitionRefundPolicy";



const Network= () => {
    return (
     
       
        <Routes>
               <Route  path="/" element={<Home/>}/>
               <Route  path="academics/academic-calendar" element={<AcademicCalendar/>}/>
               <Route  path='/academics/grading-and-academic-progress'element={<GradingandAcademicProgress/>}/>
               <Route  path="/academics/programs"element={<Programs/>}/>
               <Route  path="/academics/graduation-requirments"element={<GraduationRequirments/>}/>
               <Route  path="/academics/student-services" element={<StudentServices/>}/>
               <Route  path='/admissions/admissions-requirments' element={<AdmissionsRequirments/>}/>
               <Route  path='/admissions/enrollement-and-registeration' element={<EnrollementandRegisteration/> }/>
               <Route  path='/admissions/international-students' element={<InternationalStudents/>}/>
               <Route  path='/admissions/admission-policies' element={<AdmissionPolicies/>}/>
               <Route  path='/admissions/financial-assistance' element={<FinancialAssistance/>}/>
               <Route  path='/admissions/tuition-fees' element={<TuitionFees/>}/>
               <Route  path='/admissions/tuition-refund-policy' element={<TuitionRefundPolicy/>}/>
               <Route  path='/about-us/student-conduct' element={<StudentConduct/>}/>
               <Route  path='/about-us/administrative-policies' element={<AdministrativePolicies/>}/>
               <Route  path='/about-us/statements' element={<Statements/>}/>
              {/* <Route  path='/about-us/our-people' element={<OurPeople/>}/> */}
               <Route  path='/learning-platform' element={<LearningPlatform/>}/>
               {/* <Route  path='/apply-now/other-certificates'element={<OtherCertificates/>}/> */}
               <Route  path='/apply-now/masters'element={<MasterForm/>}/>
               <Route  path= "/contact-us" element={<ContactUs/>}/>
              
              
               

        </Routes>
    
        
    )
 
};
export default Network