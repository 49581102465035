import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./Statements.module.css"


const Statements =()=>
{

return(

 

  
  <div className={classes.content}>
      <h2>VISION STATEMENT</h2>
                    <div class="pp"><p>CIU produces leaders who are responsible, globally oriented, and immersed in practice.</p></div>
                    <br />
                    <br />
                    <div class="hh"><h2>MISSION STATEMENT</h2></div>
                    <div class="pp"><p>We produce leaders who have a deep understanding of their management responsibilities to achieve personal and societal success. We do this by delivering experiential learning in both traditional classroom and virtual environments.</p></div>
                    <br />
                    <br />
                    <div class="hh"><h2>VALUES STATEMENT</h2></div>
                    <div class="pp"><p>CIU values and promotes a culture respecting ethics, integrity, and the fulfillment of duty to our constituents, employees and society.</p></div>
                    </div>

)}
export default Statements;