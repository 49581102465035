import classes from "./AdministrativePolicies.module.css";
import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";

const AdministrativePolicies =()=>{
    return (
  
      
      
      <div className={classes.content}>
        <div className={classes.text}>
<h4>CIU offers the following degrees:</h4>

<li>Master of LAW (LLM)</li>
 
<li>Certificate in Diagnostic medical Sonography.</li>

<li>Certificate in International Arbitration.</li>

<li>Certificate in Massage.</li>

<li>Certificate in Marketing.</li>

<li>Certificate in Website Bulding.</li>

</div>
<div className={classes.text}>
<h5>Policies and Procedures</h5>
Policies and procedures may be amended at any time in accordance with State and Federal regulations.

Changes in Tuition and Fees

Prices are subject to change at any time. Students are responsible for all Tuition and Fee payments based upon the Enrollment Agreement at time of enrollment.

Program Changes

Program schedules are subject to change. CIU reserves the right to modify course content and the overall structure of the curriculum, which will be compliant with State and Federal regulations.
</div>
<div className={classes.text}>
FERPA

The Family Educational Rights and Privacy Act (FERPA) (20 U.S.C. § 1232g; 34 CFR Part 99) protects the privacy of student education records. It gives the student the right to: access education records kept by the school; consent to disclosure of student education records; amend inaccurate education records; file complaints against the school for disclosing education records in violation of FERPA. FERPA rights apply to every CIU student who is or has been in attendance at CIU, regardless of the student's age. An education record may include personal information, enrollment records, grades, and schedules, etc. Student records will be maintained at the school site for five years from the last date of attendance. Transcripts are maintained permanently. The Registrar’s Office is responsible for student record information. Under FERPA, CIU provides access to student records to school officials who have legitimate educational interest with responsibilities in the campus' academic, administrative, or service functions and have reason for using student records associated with their campus or other related academic responsibilities. “School official” may include parties such as: instructors, administrators, attorneys, trustees; or other party to whom the school has outsourced institutional services or functions.

Students who wish to review and inspect their education records must submit a written request to the Registrar's Office. Arrangements will be made within 45 days following its receipt of a request. Written consent is required before education records may be disclosed to third parties, with the exception of accrediting bodies and government agencies so authorized by law. Copying and postage fees may apply.

Students have the right to request that any inaccurate or misleading information in education records be amended.

CIU is not required to amend education records in accordance with the student's request under FERPA, but will consider the request, and will inform the student of his or her right to a hearing on the matter if CIU decides not to amend a record in accordance with the student's request.

According to FERPA, CIU may disclose, without consent, "directory" information. According to FERPA General Guidance for students and Family Educational Rights and Privacy Act Regulations "Directory information" is defined as information contained in the education records of a student that would not generally be considered harmful or an invasion of privacy if disclosed. Directory information could include information such as the student's name, address, e-mail address, telephone listing, photograph, date and place of birth, major field of study, participation in officially recognized activities and sports, weight and height of members of athletic teams, dates of attendance, degrees and awards received, the most recent previous educational agency or institution attended, grade level or year (such as freshman or junior), and enrollment status (undergraduate or graduate; full-time or part-time).The directory information is subject to release by the campus at any time unless the Registrar's Office has received prior written objection from the student specifying information that the student requests not be released. CIU continuously notifies students of the rights in the school catalog.
</div>
<div className={classes.text}>
<h4>FERPA allows schools to disclose those records, without consent, to the following parties or under the following conditions (34 CFR § 99.31):</h4>

 <li> School officials with legitimate educational interest.</li>

  <li> Other schools to which a student is transferring.</li>

  <li> Specified officials for audit or evaluation purposes.</li>

  <li> Appropriate parties in connection with financial aid to a student.</li>

   <li>Organizations conducting certain studies for or on behalf of the school.</li>

  <li> Accrediting organizations.</li>

   <li>To comply with a judicial order or lawfully issued subpoena.</li>

  <li> Appropriate officials in cases of health and safety emergencies.</li>

  <li> State and local authorities, within a juvenile justice system, pursuant to specific State law.</li>
</div>
<div className={classes.text}>
Non-directory information must not be released to anyone, including parents of the "eligible student" (a student who reaches 18 years of age or attends a postsecondary institution), without the prior written consent of the student. Non-directory information may include: Social security numbers, Student identification number, Race, ethnicity, and/or nationality, Gender.

The Department of Education has established an office and review board to investigate complaints and adjudicate violations.

The designated office is:

The Family Policy Compliance Office U.S. Department of Education 400 Maryland Ave Washington, D.C. 20202-8520.

Phone: 1-800-USA-LEARN (1-800-872-5327)

An eligible student may obtain a complaint form by calling (202) 260-3887.

Upon conferral of the degree, students are provided with a copy of their official transcript and diploma at no cost.

Students and alumni requesting additional copies of their transcript must do so online in their Popular account or in writing to the Registrar's Office and pay a $10 fee for each transcript and/or $50.00 for each diploma. Priority/Express shipping will be available at an additional cost. No official transcript(s) may be released if records are on hold for financial reasons or missing documentation.

Written consent is required before educational records may be disclosed to third parties, with the exception of accrediting bodies and government agencies so authorized by law. 
</div>
<div className={classes.text}>
<h4> CIU transcripts will show all of the following:</h4>
<ol>
<li> The courses that were completed, or were attempted but not completed, and the dates of completion or withdrawal.</li>

<li>  The final grades for each corresponding course.</li>

<li>  Credit for courses earned at other institutions.</li>

<li>  Credit based on any examination of academic ability or educational achievement used for admission or college placement purposes.</li>

<li>  Degrees and diplomas awarded.</li>

<li>  The name, address, email address, and telephone number of the institution.</li>
</ol>
</div>
<div className={classes.text}>
Records Retention Policy

CIU maintains records of names, addresses, email addresses, and telephone numbers of each student who is enrolled in an education program.

<h4>For each student granted a degree, CIU maintains a permanent record of all of the following:</h4>
<ol>
<li>The degree granted and the date on which that degree was granted.</li> 

<li>  The courses and units on which the degree was based.</li> 

<li> The grades earned by the student in each of those courses.</li> 
</ol>
</div>
<div className={classes.text}>
CIU complies with federal and state requirements regarding the retention of student records, which stipulate that student records are maintained for not less than five (5) years, at its principal place of business in California. CIU maintains transcripts indefinitely. Restricted access of authorized school officials for student records/files kept electronically and in locked fireproof cabinets.

Changes in Student File

It is the responsibility of each student, staff, or faculty member to file any changes to their current name, address, email address, and telephone number with the Registrar’s Office within five (5) days of such changes.

Student Identity Verification Policy

CIU has a process in place to ensure that the student registering for a course is the same student who participates in the course or receives course credit, primarily through the use of a secure login and pass code. The policy applies to all CIU credit-bearing courses that have a distance education component.

Secure Login and Pass Codes

CIU assigns each student a unique username and password for the Learning Management System as well as a University email. In addition, all students registering for a course at CIU are assigned unique student identification numbers. Students are assigned a password when their account is first established and they are required to change their password when they initially activate their account. It is advised that passwords must be suitably complex. CIU’s Student Services is the office responsible for issuing secure usernames and password.

All students are responsible for maintaining the security of usernames, passwords, and other access credentials as required. An account is assigned to an individual for the exclusive use by that individual. Attempting to discover another user's password or attempts to gain unauthorized access to another person's files or mail is prohibited. It is against University policy for a user to give someone his or her password or allow others to use his or her account. Users are responsible for any and all users of their account.

Additional Verifications

Faculty teaching courses through distance education methods have a role in identity verification insofar as they can be alerted to changes in student behavior. Sudden shifts

</div>

<div className={classes.text}>
in academic performance, changes in writing style or language used in discussion groups, or email may indicate academic integrity issues. It is recommended that faculty routinely use a variety of assessment instruments. Faculty should regularly ask students to share, in appropriate ways, important ideas learned from texts and references and require projects and paper assignments to be submitted in steps.

Responsibility for Policy Implementation

The Academics Office and Student Services are jointly responsible for ensuring compliance with this policy and to ensure that the Dean, department heads, and respective staff are informed of any changes in the policy in a timely fashion.

Student Identity will be verified by the following:
</div>
<div className={classes.text}>
a.  Student ID (either by the copy provided to Admissions or a school issued ID)

b.  A unique Populi and email login (username and password)

c.  The student's consistency in the writing of assignments and threaded discussions

d.  The student's presentations either in class or online

e.  The student's unique CIU email address and password
</div>
<div className={classes.text}>
Student Grievance Policy Statement

Students should be free of unfair and improper action by any member of the CIU community. A grievance may be initiated by a student when they believe that they have been subject to unjust action or denied their rights as stipulated in published school policies and State or Federal laws. Such action may be instituted by a student against a faculty or staff member and may seek redress through the Student Grievance Procedures as identified below.

Student Grievance Procedures

Academic Grievance

In most situations' communication between the student and the faculty member can resolve an academic issue. It is important to address concerns either during the course or immediately after the conclusion of the course. If the issue is not resolved to the satisfaction of the student in this stage of the process, then the student should initiate the formal grievance process using the “Grievance Resolution Form” available on Canvas or from the Office of the Registrar to express his/her concern. There are four levels of this process: the instructor, the Dean, the Chief Academic Officer, and finally the CIU Academic Council. If the instructor cannot resolve the problem, the matter should be referred to the Dean. The Dean has five (5) working days to resolve the issue. If one is needed, the Dean shall call a meeting or meetings with the parties involved. The instructor and the

 

student will be afforded the opportunity to present evidence prior to his/her deliberations regarding the incident or complaint and the Dean shall distribute the findings/decisions to the instructor and the student within five (5) working days after the meeting. If the issue is not resolved at the level of the Dean, the Chief Academic Officer will review the case and attempt to resolve the issue. If needed, the CIU Academic Council shall be the final institutional step in arbitrating the grievance and will resolve the issue forwarding their recommendations to the CAO for implement of any remediation. Complaints should be resolved within the thirty (30) days of the initial date on the Grievance Resolution Form. A student or any member of the public is not required to use CIU's internal process first.
</div>
<div className={classes.text}>
Non-Academic Grievance

All complaints are viewed with the utmost seriousness and are treated in confidence, including those associated with bullying, racial and religious vilification, sexual harassment and unlawful discrimination allegations made by students. The Director of Campus Success or Title IX has five (5) working days to resolve the issue (except in the case of Sexual Harassment, please refer to the Sexual Violence and Sexual Harassment Policy, for which the office has 60 days to investigate the matter).

Complaints may be formal or informal. The difference between the two is that an informal complaint is unwritten and often unofficial, whereas a formal complaint is documented and therefore official.

</div>
<div className={classes.text}>
Administered Three Stage Process

-First Stage of the Process: The complainant may initially raise an informal complaint (unwritten) with the Student Success Coordinator. Following receipt of the informal complaint, the issue will be reviewed by the Department Head within three (3) business days and a response will be provided to the complainant within five (5) business days. Depending on the nature of the complaint, the Department Head may choose to meet with other Directors to gain further information and resolution of the complaint raised. Student Success Coordinator will raise the complaint directly with the Director of Campus Success if the complaint is perceived to be of an extreme, threatening or criminal nature.

-Second Stage of the Process: Should the complainant not be satisfied with the outcome; they may submit a formal complaint in writing. The complainant may choose to go directly to the formal second stage if they prefer. The formal written complaint must be received by the Director of the Student Success Coordinator within 10 business days of the complainant receiving feedback. The formal complaint will be reviewed and addressed within three (3) business days and a response will be provided to the complainant within five (5) business days.

-Third Stage of the Process: If not satisfied with the decision of the Student Success Coordinator, the complainant may submit the complaint in writing to the. Director of Campus Success within 10 business days of receipt of the formal complaint decision. The complaint will be addressed within 15 business days of receipt of the complaint, and a response will be given within 30 business days. Complaints should be resolved within 30 days of the initial date on the Grievance Resolution Form. A student or any member of the public is not required to use CIU's internal process first.
</div>
<div className={classes.text}>
Filing a Grievance

A student or any member of the public may file a complaint about this institution with the Bureau for Private Postsecondary Education (BPPE) by calling

(888) 370-7589 toll-free or by completing a complaint form, which can be obtained on the bureau's internet website at http://www.bppe.ca.gov/. Grievances can also be filed with he following: Distance Education Accrediting Commission (DEAC) by completing the online DEAC Complaint Form. Further grievance information for DEAC can be found at https://www.deac.org/Student-Center/Complaint-Process.aspx. The WSCUC Complaint Process is available at https://www.wscuc.org/comments https://www.wscuc.org/content/complaint-form
</div>

<div className={classes.text}>
<h5>Student Email</h5>
We require each student to use their assigned "ciu.edu" email address as this is CIU's main form of communication with the student. All students will be assigned their own unique email through the school that 
</div>

</div>


                    
             
    );}
    export default AdministrativePolicies;