import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./Programs.module.css"

const Programs =()=>{

return(


  
  <div className={classes.content}>

<div className={classes.text}>
           
                <h2>LLM IN LAW Academic Calendar 2020-2021</h2>
                   LLM IN LAW

The Master's Degree program prepares students with the necessary skills and knowledge to understand, manage or create financial, business, and leadership careers in executive management and entrepreneurship. The program provides the tools for business and leadership professionals to develop knowledge, attitudes, and skill sets that will equip them to perform effectively, ethically, and creativity in the corporate or entrepreneurial environment. The CIU LLM can be applied to employment positions determined to be within the field under the United States Department of Labor's Standard Occupational Classification codes. Please see Appendix A, Career Services,. The program has two tracks of study. The 16-week term has three terms per year. Students are required to take 2 courses per term: one hybrid and one online allowing a student to complete the program in two years. The other option is modules of eight weeks with two classes required per term for six terms per year. This allows a student to complete the program in one year. A student may also take one class per term which would allow for the completion of the program in two years. Additionally, the program includes a mandatory internship course, INT599, that must be fulfilled in at least one term. Please see EXPERIENTIAL INTERNSHIP COURSE (INT 599) for more details. Classroom enrollment is set at a maximum of 20 students per class to allow for personalized interaction for both hybrid and online courses.



<h5>Program Objectives</h5>
1.  The program provides a comprehensive immersion in all the essential elements of business education: Accounting, Entrepreneurship, Finance, Information Systems, International Business, Ethics, Marketing,, Operations Management, Organizational Behavior, Quantitative Analysis, and Strategy. These concepts are strengthened through the threaded discussions online, through experiential learning exercises, and the consulting and case projects. Online visits by exceptionally qualified faculty researchers bring students into contact with the latest research methods in the various fields of business education.

2.  Critical thinking, problem solving, and communication skills are addressed by experiential learning exercises, frequent short presentations and the consulting project and case analysis. Faculty, peer, and consulting client feedback is effective in teaching the characteristics of effective critical thinking and professional communication techniques.

3.  Teamwork and leadership, are taught as concepts and practiced in class through experiential learning and the team-based consulting and case projects.

4.  The students will learn the effective use of technology to solve management problems in all classes. Consulting includes international clients that help students develop and apply technological skills to overcome the impediments of distance and geographical time differences.

Student Learning Goals and Corresponding Student Learning Outcomes (SLO's)

Learning Goals #1 through #5 are program-driven goals and they constitute the five overreaching goals of the MBA program. They could be labeled "Institutional Goals" or "Program Goals". Each of these five goals is assessed in each of the twelve courses of the MBA program.

<h5>Learning Goal #1. Verbal/Visual Presentation Skills</h5>
Students completing the CIU LLM program will demonstrate the ability to verbally and visually communicate effectively in a business setting when making presentations.

<h5>Corresponding Student Learning Objectives:</h5>
a.  Students will demonstrate verbal communication skills by delivering effective communications.

b.  Students will demonstrate visual communication skills by effectively utilizing and employing computer software with bot content and the display of information.

<h5>Learning Goal #2. Written Skills</h5>

Students completing the CIU MBA program will demonstrate the ability to effectively communicate in written format in a business setting.

<h5>Corresponding Student Learning Objectives:</h5>

c.  Students will demonstrate written communication skills at the time of admission exemplified in the completion of an essay involving critical thinking and competent English Language usage.

d.  Students will demonstrate written communication skills by producing professional-quality business documents, including threaded discussion dialogue, research papers and consulting reports.

<h5>Learning Goal #3. Leadership Skills</h5>

Students completing the CIU LLM program will demonstrate the ability to effectively lead a group in a business environment.

Corresponding Student Learning Objective:

e.  Students will demonstrate effective leadership as evidenced in the production of a course consulting project.

<h5>Learning Goal #4. Teamwork</h5>

Students completing the CIU LLM program will demonstrate the ability to work in a professional team setting producing material commensurate with their assigned responsibilities.


<h5>Corresponding Student Learning Objective:</h5>

f.  Students will demonstrate collaboration, contribution, and timeliness in achieving common team goals associated with the production of a client consulting project.

<h5>Learning Goal #5. Consulting</h5>

Students completing the CIU MBA program will demonstrate the ability to work in a team to provide a written and verbal consulting project fulfilling the
contractual obligations of a consulting proposal with a client.

<h5>Corresponding Student Learning Objective:</h5>

g.  Students will demonstrate interviewing skills, client needs, contract development, industry research, and produce a verbal, visual, and written report commensurate with the contract proposal.

Student learning outcome goal #6 is a course-driven goal providing the MBA student with both functional knowledge and analytic tools to be successful as managers in today's ever-changing workforce. With the twelve courses of the MBA program, many of the topics and tools are introduced in the first few courses, developed in the next block of courses (with overlapping topics in many cases to provide intentional redundancy), and finally mastered at the graduate level in the courses offered at the end of the MBA program. Each course, to some extent, has components that constitute both analytic and functional elements.

Each of the twelve courses in the MBA degree program have course student learning outcomes (SLO's) that are quantified with a "Pre" and "Post" knowledge survey instrument completed by the students at the beginning of each course and then at the end of each course. There is a direct mapping between the knowledge survey questions and each of the course's SLO's. Additionally, each of the eleven lessons in each course have individual lesson student learning outcomes. At least one of the lesson's SLO is measured and mapped to the "Pre" and "Post" Knowledge Survey instrument.

<h5>Learning Goal #6. Functional Knowledge and Analytic Knowledge</h5>

Students completing the CIU LLM program will demonstrate knowledge of fundamental principles in information assurance and critical thinking skills, integrating knowledge across business disciplines from a global, ethical, and socially responsible perspective. Additionally, students completing the CIU LLM program will demonstrate the ability to use analytical techniques and information technology to solve business problems.

<h5>Corresponding Student Learning Objectives:</h5>

h.  Students will demonstrate the ability to apply principles of theory and accepted "best practices" within business environments.
i.  Students will be able to identify problems, evaluate the quality of the evidence, and undertake appropriate analyses to produce appropriate solutions.

j.  Students will be able to integrate across analytic components of data to identify relationships and
interrelationships to apply theoretical constructs in a business setting.

<h5>Instructional Methods</h5>

Our instructional methodology includes lecture, experiential learning exercises, individual and group presentations, and the application of principles to the student’s current job or profession. Students will also be exposed to speakers from the industry on a regular basis. In addition, our students will be involved in consulting projects with small businesses as a way of putting into practice the concepts they are learning in the classrooms.

<h5>Try Before Buy Program</h5>

CIU has a "Try Before Buy program" (TBB) where we want the student to be 100% certain before spending a penny for the degree. CIU assumes all the risk and the student can take one (1) trial course. Only after the seven (7) week term and the student decides to continue with the program, then the student will pay for the course taken and continue necessary payments for the MBA program. The student will receive a "No Grade" (NG) if the student cancels the trial course or decides not to enroll in the MBA program after completion of the trial course. A record of "NG" (no grade) will be marked for the trial course in such circumstances. Only one (1) TBB course allowed per student within a 5-year period. Additional TBB courses after the 5-year period requires approval from the Dean. Please contact the Enrollment Executive (admissions@ciu.edu) for more information.
Teaching Models

Each course consists of the following activities: reading textbook and library articles. watching lecture-related videos, preparing and delivering presentations, preparing and participating in at least three postings (threaded discussions), researching and writing paper assignments, team group work for consulting or case studies, and experiential learning exercises.

<h5>Hybrid Class Hours</h5>

Each hybrid class involves 46 hours of total time (6 sessions of four hours and ten minutes on-ground and 5 sessions online of equal quality and quantity). The students are required to engage in additional reading and homework of approximately 2 hours for every one hour of class in the hybrid format making a total class engagement of 138 hours.


<h5>Online Class Hours</h5>

The online classes require the students engage in each class for 138 hours. Our online classes require the same syllabi, student learning outcomes and all classes are equivalent in quality and quantity.

<h5>Course Structure</h5>

Course Numbering System: The first two or three letters represent the subject and all courses are given 500 numbers. Our MBA program is modular, and students can enter the program on any given term. There are multiple sections of courses offered throughout the year for students that are in a situation in which they need to make-up a course. Students may join other cohorts in these situations with the Dean's approval.

<img src= "Table9.PNG"/>

       TOTAL Semester Credits
                                                                       36.00  
<h5>MIDECAL SONOGRAPHER PROGRAM: (9)</h5>

SONO 218 - Abdominal Sonography (3)
Add to Portfolio Print Course
SONO 218 - Abdominal Sonography (3)
Prerequisites: SONO 101 , SONO 136 , SONO 230 
All Sonography prerequisites must be completed with a minimum grade of "C+" (2.30).
This course will present normal anatomy, sonographic techniques and protocols, assessment, and interpretation of major abdominal organs and superficial structures. Emphasis will be placed on evaluation of the liver, gallbladder, pancreas, abdominal vessels, retroperitoneum, spleen, thyroid, and breast. A study of laboratory values and pathologic conditions will also be explored.


SONO 220 - Vascular Sonography (3)
Add to Portfolio Print Course
SONO 220 - Vascular Sonography (3)
Prerequisites: SONO 136 , SONO 101 
All Sonography prerequisites must be completed with a minimum grade of "C+" (2.30).
This course is an exposition of normal and pathological conditions associated within the circulatory system. It is an examination of the circulatory anatomy as well as the evaluation of hemodynamics and the impact of associated disease processes. Both direct and indirect testing methods will be presented in the determination of diagnosis and/or progression of vascular disease. Test Validation will be explored. Correlative imaging techniques will be discussed as well. This course meets the requirement for an oral communication course.

SONO 237 - Obstetrical Sonography (3)

Add to Portfolio Print Course
SONO 237 - Obstetrical Sonography (3)
Prerequisites: SONO 101 ,  SONO 230, SONO 136 , 
All Sonography prerequisites must be completed with a minimum grade of "C+" (2.30).
This course presents normal anatomy and sonographic techniques related to first and second trimester obstetrical imaging. Accessory testing will be reviewed, including various screening and diagnostic tests offered to the obstetrical patient. Pathology identified in the first and second trimesters will also be explored to include ectopic pregnancy, gestational trophoblastic disease, placental abnormalities, and congenital anomalies. Maternal diseases and possible effects on the fetus will also be reviewed.



</div>
</div>

   
);}
export default Programs;