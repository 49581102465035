import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./InternationalStudents.module.css"

const InternationalStudents =()=>{
    return(

    


      
      
      <div className={classes.content}>
         <div className={classes.text}>
          <h2>International Students</h2>
                    
Foreign Transcripts and Its Evaluation

All students who have obtained their undergraduate degree outside of the United States must have their official degree transcripts evaluated by a 3rd party evaluation service to show what type of degree the student has and the grade equivalence (GPA) in the U.S. format. The official document-by-document credential evaluation with the GPA calculation must be sent directly to CIU by the evaluating party via email or mail. A copy of the original transcripts must be submitted to CIU in addition to the evaluation report. Please refer to http://naces.org/members.htm to select an evaluation service. The approved evaluation services listed at www.naces.org are not related to CIU and any fees for the evaluation service will be the responsibility of the student.


If a student wishes to transfer courses from a foreign university, then a course-by course evaluation (GPA calculation included) will be required. See Transferring Credits for more information. 


If your transcripts are issued in a language that is NOT English, you will be required to have your transcripts translated.

<h5>International Students with a U.S. Bachelor's Degree or higher</h5>
If you received a Bachelor's degree from an accredited university in the United States, then you are not required to submit a Foreign Degree Evaluation. The official transcript from the U.S. institution is required and it can be sent directly from the issuing institution in the U.S. via email or mail. The official transcript can also be presented by the applicant in person, or mailed it to CIU in a sealed envelope.

If you have a Bachelor's Degree from overseas, but a Maste's degree (or higher degree) from the U.S., the Foreign Degree Evaluation Is not required. The official U.S. transcript is required for the application. The transcript can be sent directly from the issuing institution in the U.S. via email or mail. The official transcript can also be presented
by the applicant in person, or mailed it to CIU in a sealed envelope A scanned copy of the undergraduate transcripts issued by the international institution are also required


<h5>Conditional Admission-International Student</h5>
International Student Conditional Admission is offered to international students who are academically qualified and wish to study in a U.S. Institute, but cannot meet the English requirement for acceptance. Report writing skills and presentation skills are essential for student performance in the CIU LLM program. Students with conditional admissions must first attend an intensive English Language program in the US at the American English College (AEC), a CIU approved Language school in Los Angeles. Once the student has completed their English language program, or shown satisfactory English language scores, the students may then transfer to full-acceptance and begin CIU's LLM program.

The Applicant is responsible for additional supporting documentation and fees required by AEC. At any point in the AEC program, if the student feels that she/he has reached a sufficient grasp of the English Language and wishes to be fully admitted at CIU, the student should speak with his/her AEC advisor to request to "Opt-Out" of the AEC program early by taking the TOFEL, IELTS, Pearson Academic, or ITEP and achieving the minimum score required by CIU. The students can request more information about ITEP and "Opting-Out" from their AEC advisor.


<h5>Required Documentations for Conditional Admission to CIU</h5>
-   CIU Online Application
-   Application Fee ($50)
-   Most Current Resume (Upload it to the online 
-   application)
-   Scanned Copy of the U.S. government issued photo ID or Biological page of your Passport (from your home country; upload it to the online application)
-   A scanned copy of the Bachelor's transcript from an accredited institution overseas

Optional Docs (Required for a full admission to MBA program)

-   Document-by-document evaluation with GPA calculation
-   Submit English test score to CIU (If Applicable)


For more detailed instructions on the above items, please review the INTERNATIONAL ADMISSIONS section above or our International Conditional Admissions Checklist.
Apply for Conditional Admission to CIU

Please see the Admissions Requirement section and Additional Admissions Requirements - (International Students) section. Once the international applicant submits the required documentations for conditional admissions to CIU listed above, a conditional acceptance letter will be issued from the Office of Admissions via email and mail. An Affidavit of Financial Support Form, along with a bank statement, will be required prior to the enrollment at CIU.

Additional Academic Policies for International Students Enrollment Requirements

International students studying in the United States with an F-1 visa are required to take two classes per term and one of the two must be in the hybrid mode. Students seeking to take courses online from the other CIU formats must request permission via email to dean@ciu.edu and receive authorization from the Dean prior to registration.
enrolled in school for one full academic year. Students authorized to participate in pre-completion OPT must work part-time while school is in session. They may work full time when school is not in session.

-   Post-completion OPT: F-1 Students may apply to participate in post-completion OPT after completing their studies. Students authorized for post-completion OPT may work part-time (at least 20 hours per week) or full-time.

Rules established by USCIS govern the implementation of OPT, and all OPT employment requires prior authorization from USCIS and from CIU.

Students cannot begin employment until they receive their Employment Authorization Document (EAD card) from USCIS, AND have been enrolled for at least one year. However, students can start to submit their application for OPT up to 3 months ahead of time after only being enrolled for 9 months. Students also do not need to have a job offer to apply for OPT EAD card, and OPT employment can occur anywhere in the U.S. However, you may accumulate no more than 90 days of unemployment while on post completion OPT. Nonetheless, students are recommended to start early since USCIS takes up to 90 Days to process the application. Permission is based on maintaining lawful F-1 status.

You may be authorized for a maximum of 12 months, for each level of education you complete. OPT time used before the completion of a program is deducted from the available 12-month period of post-completion OPT. Part-time work during pre-completion OPT will be deducted at half the fulltime rate. For instance, if the student works part time for 6 months, only 3 months will be taken from post-OPT and he/she can work full-time for up to 9 months after graduation. Time will be counted by weeks granted, not by hours worked.



<h5>Eligibility Requirement</h5>
1.  Employment must be "directly related" to the student’s major (MBA in Executive Management and Entrepreneurship)
2.  Student must maintain lawful F-1 status
3.  Student must apply for OPT before completion of all work towards the MBA degree
4.  Students who have engaged 12 months or more of full-time Curricular Practical Training (CPT) are not eligible for OPT
5.  OPT is permitted for up to 12 months full-time in total. Applying for OPT

You can file an application for Post-completion OPT as early as 90 days in advance of your completion date and up to 60 days after your completion date. We recommend that you file early, as it can take up to 90 days for an application to be approved.

Your completion date is the last day of your last term at CIU. Your completion date is located on your I-20. You may NOT continue to work on campus after your program completion date. Your OPT start date must fall within your 60-day grace period. It may be as early as the day after your program completion date or as late as 59 days after your program completion date. Since standard OPT is authorized for up to 12 months, your OPT end date would be exactly 12 months after your OPT start date.



<h5>To apply for OPT:</h5>
1.  Download and complete the USCIS Form I-765 (Application for Employment Authorization). It is recommended that you type the form. If you choose to hand-write the form, make sure to use black ink and sign the form using black ink. Instructions for completing the form can be found by CLICKING HERE. Once you complete the form, save it or scan it to a PDF file.

2.  Scan the following documents to PDF files:

    a.  Your passport photo page

    b.  Your most recent F-1 visa

    c.  Your current I-94

    d.  Copies of your previous I-20s

3.  Submit the OPT Request Form & your completed Form I-765 to your DSO, including your PDF documents listed above. Please email at DSO@ciu.edu for the OPT Request Form.

4.  Your application will then be reviewed by your DSO and the Registrar for certification of your program completion date. If your application is complete the DSO and the Registrar for certification of your program completion date. If your application is complete the DSO will issue you a new I-20 with a recommendation for OPT. If there are any concerns regarding your application, you will be notified via your CIU email address. Application processing time may vary; it may take up to two (2) weeks for your application to be processed during peak times.

5.  Sign and mail a copy of your new I-20 and the Form I-765 (Application for Employment Authorization), to USCIS. After you mail the application to USCIS, it can take up to 90 days to approve your application and send you the card. When mailing
Contact admissions@ciu.edu with any questions.

</div>


</div>
    );}
    export default InternationalStudents;