import React from "react";
import { Nav, NavLink, Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <main style={{ minHeight: "auto" }}> 
        {/* Your main content goes here */}
      </main>
      <Container fluid className="footer bg-dark text-light py-4">
        <Row>
          <Col className="text-center">
            <h6 className="text-white">California International University</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav className="justify-content-center">
              <NavLink href="/contact-us" activeClassName="active" className="nav-link text-white">
                Contact Us
              </NavLink>
              <NavLink href="/academics/grading-and-academic-progress" activeClassName="active" className="nav-link text-white">
                FAQ
              </NavLink>
              <NavLink href="/about-us/administrative-policies" activeClassName="active" className="nav-link text-white">
                Administrative Policies
              </NavLink>
            </Nav>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
