import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./TuitionRefundPolicy.module.css"
const TuitionRefundPolicy =()=>
{
    return (

      
      
      <div className={classes.content}>
             <div className={classes.text} >
          <h2>Tuition Refund Policy</h2>
                    
<h5>Student's Right to Cancel</h5>
The student has the right to withdraw from the program for a full refund of tuition during the cancellation period. Note the application fee* and Student Tuition Recovery fund fee are not refundable. The cancellation period encompasses seven (7) calendar days from enrollment or through the first-class session, whichever is later. After the cancellation period, the student may withdraw and receive a pro rata refund of tuition paid if notice of withdrawal has been submitted before sixty (60%) percent or fewer scheduled days have passed. The amount owed equals the daily charge for the program (total institutional charge, divided by the number of days in the program), multiplied by the number of days the student attended, or was scheduled to attend, prior to withdrawal. Students on full CIU scholarships or 100% institutionally funded tuition by CIU may cancel their enrollment at any time. Students using VA, please see Veterans Addendum for more information.

Cancellation shall occur when the student gives written notice of cancellation to the university at the following address:
1000 S Fremont Ave. Mailbox #45 Building A10, 4th Floor, Suite 10402 Alhambra, CA 91803

Students can also do this by mail, hand delivery, or email. The written notice of cancellation need not take any particular form and, however expressed, it is effective if it shows that the student no longer wishes to be bound by the agreement.

If the Enrollment Agreement is cancelled the school will refund the student any money paid, less an application fee* of $50.00 within 30 days after the notice of cancellation is received.

*The application fee is refundable for military students.



<h5>Official Withdrawal from the Program</h5>
Withdrawal from CIU is a matter of major importance. If you are considering withdrawal from school, discuss the matter with the Dean prior to initiating action.

Students may withdraw from the school at any time after the cancellation period and receive a pro rata refund if they have completed 60 percent or fewer of the scheduled days in the current payment period. The Student Tuition Recovery fee and the application fee not to exceed $50.00, and the cost of any equipment not returned in good condition will be deducted from the refund. The refund will be made within 30 days of withdrawal. If the student has completed more than 60% of the period of attendance for which the student was charged, the tuition is considered earned and the student will receive no refund.

For the purpose of determining a refund under this section, a student shall be deemed to have withdrawn from a program of instruction when any of the following occurs:

-   The student notifies the institution in writing.

-   The institution terminates the student's enrollment for failure to maintain satisfactory progress; failure to abide by the rules and regulations of the institution; absences in excess of maximum set forth by the institution; and/or failure to meet financial obligations to the School.

-   The student has failed to attend class as stipulated in the California Institute of Advanced Management attendance policy.

-   The student fails to return from a leave of absence.

For the purpose of determining the amount of the refund, the date of the student's withdrawal shall be deemed the last date of recorded attendance. The amounted owed equals the charges for the current payment period, divided by scheduled days in the current payment period, multiplied by days attended prior to withdrawal. For the purpose of determining when the refund must be paid, the student shall be deemed to have withdrawn after failing to attend class as stipulated in CIU's attendance policy. If the student has completed more than 60% of the period of attendance for which the student was charged, the tuition is considered earned and the student will receive no refund.

If a student prepays tuition and fees and withdraws prior to the conclusion of the cancellation period for the next term, all charges collected for the next period will be refunded in accordance with the cancellation policy. If any portion of the tuition was paid from the proceeds of a loan or third party, the refund shall be sent to the lender, third party or, if appropriate, to the state or federal agency that guaranteed or reinsured the loan. Any amount of the refund in excess of the unpaid balance of the loan shall be first used to repay any student financial aid programs from which the student received benefits, in proportion to the amount of the benefits received, and any remaining amount shall be paid to the student.

If the student has received federal student financial aid funds, the student is entitled to a refund of moneys not paid from federal student financial aid program funds.

Sample Refund Calculation

Application Fee (Non-Refundable): $50.00

Course tuition paid: $1,875.00

Scheduled days per course: 49 days

Days attended: 20 days

Tuition earned: $765.31

Tuition refund due to student: $1,109.69

Unofficial Withdrawal

Withdrawal from CIU is a matter of major importance. If you are considering withdrawal from school, discuss the matter with the Dean prior to initiating action.

If you stop attending all of your classes without officially withdrawing or if you fail all courses within a term, it is considered an unofficial withdrawal. The instructor may report a grade of "I" (Incomplete) if there is reason to believe that the absence was beyond the student's control; otherwise the instructor shall record a grade of "F" and last date of documented/recorded class activity. An "F" grade will be on the student's academic record.
For financial aid recipients, upon determination of the student's last date of attendance, the Return of Title IV funds will apply. Any student for whom a repayment calculation results in a required return of federal funds will be responsible for repaying those funds prior to receiving additional federal financial aid at CIU or any other institution. You will not be eligible to receive additional Federal Title IV financial aid at CIU or any other institution until the federal aid has been paid back. Please also see Post-Withdrawl Poilcy

</div>
</div>

    );}
    export default TuitionRefundPolicy;