import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./GradingandAcademicProgress.module.css"


const GradingandAcademicProgress =()=> {
    return(

    
     
      
      <div className={classes.content}>
            <div className={classes.text}>
          <h2>Grading and Academic Progress</h2>
                    
Credit Hour Policy

This credit hour policy applies to all courses and programs at CIU that award academic credit regardless of the mode of delivery including, hybrid and distance education.

A credit hour represents the amount of work governed by intended and clearly identified student learning outcomes and verified by evidence of student achievement.

Assignment of credit hours for courses will occur during program/course approval processes and be monitored through faculty, curriculum, and program reviews established by the university.
Every semester unit is equivalent to 15.33 clock hours; for a total of 552 clock hours for 36 semester units. Our on-ground courses and online courses have the identical learning outcomes. The online material has references to textbooks, links, and educational videos that parallel the time spent by hybrid students in the classroom. The material presented in online lessons qualitatively and quantitatively equates with on-ground classes. Learning outcomes and their assessments are the same for all hybrid and online students.

The total time expected of students actively participating in each CIU course is 113-138 hours for the whole duration of the term. This time can be broken into the following categories: watching lecture-related videos and PowerPoint presentations: 8-10 hours, preparing and delivering presentations: 5-7 hours, preparing and participating in at least three postings (threaded discussions): 6-7 hours, research and writing paper assignments: 24-28 hours, team group work for consulting or case studies: 20-26 hours, experiential learning exercises: 5-6 hours; reading textbook and library articles 45-54 hours. Each course in the MBA and Accelerated LLM tracks are equivalent to three (3) semester units.

There are three options for student to consider for the CIU LLM degree:

1.  Enrolling in two (2) courses per term (one of which is hybrid and one is online) to earn the degree in two (2) years.
2.  Accelerated tracks:

a.  Enrolling in two 8-week courses per term to earn the degree in one (1) year.

The LLM program consists of one hybrid course that meets during one weekend per month per term and a second course which is online that parallels the hybrid course. A student in this track takes two courses per term.

In the Accelerated track, the hybrid course meets once a week for six (6) weeks in class. Each class meets for four (4) hours and 10 minutes per week. In addition, there are five

(5) online lessons in each hybrid course, which is completed in eight weeks per term.

Prior to Enrollment, the student will specify the program track. Students are not allowed to register for more than six
(6) semester credits, (2 courses) per term. A full-time student is an individual enrolled in six (6) semester credits per term. A part-time student is a student enrolled in less than six (6) credits per term.

<h5>Impact on Grades on CGPA</h5>

Grades "A", "B", and "C" (+/-) specific grades are counted in the calculation of CGPA, where a grade of "I" (Incomplete), "P" (Pass), "NP" (No pass), and "W" (Withdraw) are not counted in the calculation of CGPA. *D grades are not given because anything below a C- is a failing grade.

All course work completed at CIU is counted in CGPA where repeated course work from a previous passing or failing grade and transfer course work do not count towards CGPA.

Please refer to Tables 1 and 2 below.


<b>Table 1
Impact of Grades on Graduate Cumulative SAP GPA</b>

 <img src= "Table1.PNG"/>


<b>Table 2
Impact of Course Type on Graduate Cumulative GPA</b>

<img src= "Table2.PNG"/>


<h5>Pace of Progression Requirement</h5>
To maintain satisfactory progress, graduate students must complete a minimum number of units each predetermined point in the program (Pace of Progression) to ensure completion of the degree within the maximum time frame.

The units attempted and completed with grades, "A", "B", and "C" (+/-), "W" and "I" are counted in the calculation of the "Pace of Progression" as well as in the computation of the maximum time frame.

All course work attempted and completed at CIU, including repeated course work from a previous passing or failing grade(s) and all accepted transfer course work counts towards the "Pace of Progression" and the maximum time frame.

Please review Tables 3 and 4.

<b>Table 3

Impact of Grades on Pace of Progression and Maximum Time-Frame Allowance</b>
 
<img src= "Table3.PNG"/>
 <b>Table 4

Impact of Course Type on Pace of Progression and Maximum Time-Frame Allowance
</b>


<img src= "Table4.PNG"/>
<h5>Maximum Time-Frame Allowance</h5>

To demonstrate Satisfactory Academic Progress, students must complete their degree objective within a specified amount of time. The time frame will depend on the student's enrollment status and educational objective.


<b>Table 5

Full-Time Course Load for Determining Maximum SAP

Units and Semesters
</b>

<img src= "Table5.PNG"/>
<h5>Grades and Grade Point Average</h5>
All students must meet the minimum standards of academic progress while enrolled at CIU. Failure to maintain the minimum cumulative GPA of 3.0 will result in the student being placed on probation.

CIU does not have a cumulative final test or examination required for the completion of any of the program. Grades are based upon the quality of work completed, i.e., upon the actual accomplishment in courses offered for credit. The GPA is computed by dividing all grade points earned by total units completed.

All assignments are graded by the faculty within three (3) business days after the assignment deadline. All final grades are submitted by faculty to the Dean and Registrar and posted on Canvas (gradebook) within three (3) business days following the end of the course. Comments are given from the professor to the student for submitted assignments and discussion forums in which the student participates.



<h5>Grading System</h5>
CIU's grading system consists of the following designations:
<img src= "Table6.PNG"/>

<img src= "Table7.PNG"/>
Student evaluations are given at the end of each term. The qualitative evaluation is done by the instructor or Dean and quantitative evaluation is done by the Registrar.



<h5>Incomplete Grades</h5>
Incomplete academic work for unforeseeable, emergency, and justifiable reasons within two (2) weeks of the end of the term may result in an "I" (Incomplete) grade. When an instructor assigns an "I", he/she shall specify in writing the requirements the student shall fulfill to complete the course as well as the reasons for granting an "I". The instructor shall retain a copy of this statement in his/her grade records and provide copies to the Dean's office.
The Incomplete is not used in calculating a grade point average and no credits will be earned until a final grade is entered. A course with a grade of "I" must be completed within six (6) months or the "I" will be changed to an "F". A student may not re-enroll in a course if he/she has an unresolved the Incomplete grade for that course. To remove the "I" grade, the student must contact the instructor who taught the course or the Dean in the absence of the instructor, to complete the grade change process within the allowed time frame. A final grade will be assigned by the instructor or the Dean (in the absence of the instructor) when the work assigned has been completed and evaluated. The temporary grade of "I" must be changed to a grade (e.g., A, B, C, D, or F) for completion of the program to be awarded a degree from CIU. If the student cannot remove the "incomplete" within the allowed time frame due to extenuating circumstances and has a current grade of "B" or better in the current course that the student is enrolled in, the student may petition the school to request an extension of time; approval is not guaranteed.


<h5>Add / Drop Period</h5>
During the Add/Drop period, students may add or drop individual courses. If a student drops all courses, this will be considered an automatic student withdrawal. Dropped courses that occur within this period will not appear on students' transcripts nor will these dropped courses be included in evaluating satisfactory academic progress (please see Satisfactory Academic Progress). Nonimmigrant students must be enrolled on a full-time status (2 courses/6 credits per term) and any course drop will affect Visa status in the U.S. Student must consult with the DSO before the drop. The student will receive a pro rata refund of tuition paid if notice of drop as been submitted before sixty (60%) percent or fewer scheduled days have passed (Please see Tuition Refund Policy). New students that have not been enrolled prior to the first class may be admitted during this period provided professor approval.



<h5>Course Withdrawal</h5>
A student who withdraws from a course may do so only with advisement from the Dean. The deadline for Withdrawal is before the end of the fourth (4th) week of the Accelerated MBA and before the end of the eighth (8th) week of the MBA program. The student who withdraws from a course prior to its completion will be assigned the grade of "W" (Withdrawal). This grade is not calculated in the cumulative grade point average, and no credits will be earned. Incompletes and Withdrawals do not affect the CGPA. However, repeated grades are calculated as part of CGPA. Since all CIU courses are required, the course will need to be retaken prior to the awarding of the MBA degree. See "Course Repetitions" below. The grade of "F" will be assigned if any student who drops after the fourth (4th) week of the Accelerated MBA program (8-week track), or the eighth (8th) week of the MBA program (16-week track).

<h5>Course Repetitions</h5>

A student is required to repeat any course in which a grade of "F" (Failure) or "W" (Withdrawal) was received. Students have the option of repeating a course with a grade earned of "B-" or lower. A student may not repeat a course for academic credit for a course with an earned grade of "B" or better. The new grade earned will replace the original grade for the purpose of calculating the cumulative grade point average. However, courses in which an "F" is received will be considered credit hours attempted for the purpose of determining GPA. The student will be responsible for tuition fees incurred for any repeated courses. Student may repeat the same course only once and are not allowed to repeat more than three (3) courses in the entire MBA program. Repeated coursework at CIU will be counted towards the "Pace of Progression" and the maximum time frame.

<h5>Withdrawal from School</h5>

If a student chooses to withdraw from school, the student needs to provide a written notice. The student will be eligible for reinstatement for a period not to exceed six (6) months from the date of the written notice. If a student withdraws without notice, reinstatement will be at the discretion of the Academic Council and earned credits will be counted if the period between withdrawal and re-instatement is six (6) months or less any monies paid are subject to CIU's refund policy (please see Tuition Refund Policy section), Students may take only one leave of absence from the day the first course begins till the last course ends.

<h5>Academic Standing</h5>

<h5>Academic Warning Policy</h5>

The Registrar reviews Academic Standing progress every term. Any student, whose CGPA falls below a 3.0, will be placed on academic warning for the next term. Students on academic warning will have one (1) term to return to good standing by raising his/her CGPA to a 3.0. Students on academic warning are still eligible for CPT or INT 599. Grades and credits earned during an academic warning period will count towards CGPA and pace of progression, and the maximum time frame. If a student fails to raiser his/her CGPA to a 3.0 at the end of the academic warning term he/she will be then placed on academic probation and no CPT will be authorized nor will the student be allowed to register for INT 599

<h5>Academic Probation Policy</h5>

The academic probationary period is two (2) terms in which time the student must return to good standing by raising his/her CGPA to a 3.0. If a student does not raise his/her CGPA to at least a 3.0 by the end of the probationary term, he/she will be placed on “Suspension” (See below for suspension policy). Grades and credits earned during a probationary period will count towards CGPA and pace of progression, and the maximum time frame. Students on academic probation are no longer eligible for CPT or INT 599 and will not be eligible for CPT or INT 599 until the academic probation is lifted.

A student may be placed on "Conduct Probation" should the following behaviors occur: disruptive or disrespectful behavior toward staff, faculty, students, or consulting clients; theft of property, use of indecent or profane language, cheating and/or plagiarism in any form of work, repeated violations of the school dress code, harassment of instructors, clients, or other students; or discrimination of any kind. All conduct rules apply to the classrooms, school buildings, consulting client meetings and CIU parking areas.

At the end of each term, The Registrar's Office reviews the student's grades, conduct, and pace of progression to determine the academic status of the student in accordance with CIU's policies of Satisfactory Academic Performance (SAP); subsequently, the Registrar makes recommendations to Student Success and subsequently the Dean. The student will be placed on "Academic Warning", "Academic Probation" or "Conduct Probation" via email. Student Success and or/the Dean will contact the student to schedule a meeting within the first week of the next term. The Dean and Student Success will make recommendations for the next steps the student should take. A written Academic Success Plan will be completed by the student and signed by both the student and the Dean, for the student to return to good standing. This plan will be placed in the student's official file. Students have the right to appeal the probation determination to the Academic Council. When a student on academic warning or academic probation raises his or her cumulative GPA to at least 3.0, the student is removed from warning or probation.


<h5>Suspension Policy</h5>
At the end of the probationary term, any student who fails to raise his/her CGPA to the minimum requirement of a "3.0" will be placed on "Academic Suspension". The Registrar will inform the Dean who will then contact the student via email to inform him or her about their academic status. The student will have an "Academic Suspension" hold placed on his/her account and will not be able to register for any future CIU courses.
"Academic Suspension" normally lasts one (1) term. The statement "academic suspension" is placed on the student's academic record. A student who has been informed of the academic suspension may submit an appeal in writing to the Dean for reconsideration. Suspended students may be readmitted after the suspension period by submitting a written request for readmission to the Dean. A student will be notified by written notification from the Dean. A student who is reinstated to the school after having been academically suspended must achieve a term GPA of 3.0 or better for the term of reinstatement or be academically dismissed. This is an opportunity to repeat a prior course or prior courses in which the student received low grades that affected their CGPA. Please see the "Course Repetitions" section on effects on CGPA, "Pace of Progression" and the maximum time frame.

Student suspensions are disciplinary actions at the discretion of the Dean. The school will notify the student in writing of the suspension through a "Student Notice". Any student suspended may appeal that decision to the Dean. If the review of the appeal is positive, the student will be removed from "Academic Suspension" and be placed back on "Academic Probation". Should a student fail to respond to a "Student Notice" issued by any administrative or instructional personnel, that student will be suspended from class until proper contact with the issuing department has been made.



<h5>Dismissal Policy</h5>
When a student on "Academic Suspension" completes their one (1) term suspension, they may enroll in classes at CIU and they must demonstrate academic progress towards a CGPA of "3.0" or better. When the target CGPA is achieved, the student will be removed from "Academic Suspension". Students who fail to maintain satisfactory progress (as determined by the Dean) towards a 3.0 CGPA after the suspension term will be placed on "Academic Dismissal" and will not be allowed to continue with the program.

Grades and credits earned during the period after Academic Suspension will count towards the CGPA, the "Pace of Progression" and the maximum time frame.

Student dismissals (terminations) are disciplinary actions at the discretion of the Dean. A student may be dismissed for the following reasons:

1.  Failure to adhere to any probation plan developed by the appropriate administrative personnel.

2.  A third (3rd) probation of any kind.

3.  Excessive violations, based upon assessment and

Recommendation by the appropriate
administrative and/or instructional personnel, with the approval of the Dean.

In any event, should a student be on probation and found to be violating any school rules and/or attendance policy, the student may be dismissed from school for "Probation Violation."

The school will notify the student in writing of the dismissal through a "Student Notice". Any student dismissed may appeal that decision. Any student dismissed may apply for reinstatement. Reinstatement is subject to the Dean's discretion and space availability. Failure to attain a 3.0 GPA in the subsequent term results in academic dismissal. Academic dismissal normally is permanent unless, with good cause, the student reapplies and is accepted under special consideration by the Dean of the school. A student placed on Academic Dismissal is required to wait one (1) year and then apply for reinstatement. The statement "Academic Dismissal" is placed on the student's academic record.

<h5>Appeals</h5>
A student may appeal any decision regarding their progress, probation, suspension, or dismissal. All appeals must be submitted in writing to the Dean within two (2) weeks of the action causing the appeal. The letter of appeal should include any reasons or extraordinary circumstances as to why the decision should be reversed. The appeal will be reviewed, and the student will be notified of a decision within 30 days. A student may appeal an appeal that the Dean did not approve to the Academic Council within two (2) weeks of the determination.



<h5>Appeal Process Guidelines</h5>
The student may appeal and must illustrate the mitigating circumstances that prohibited successful achievement of higher education cumulative GPA or unit completion. A written appeal may be submitted with supporting documentation which must include:

-   An explanation of why the student has failed to meet the minimum GPA/unit completion.
-   An explanation of how the student resolved or plans to avoid the mitigating circumstances that impeded satisfactory academic progress
-   A concise plan for successful achievement of GPA and/or unit completion in the future.
-   The Student must have met with the Dean and developed a plan for satisfactory academic progress for future terms. He/she must include any other supporting documentation that may be relevant to his or her case (e.g. medical/doctor's notes, etc.).
Appeal Review and Outcome

Appeals are reviewed by the Dean, and the student will be notified of the outcome in writing. An appeal will be approved for one (1) term only. There are no retroactive appeals. The student must have made satisfactory academic progress (3.0 GPA graduate and completed 100% or more of units attempted for that term).

While the appeal is being reviewed, the student may continue with the program until a decision is made with the understanding that the appeal may not be granted. If the appeal is granted, the student will be allowed to continue with the program with the understanding that SAP must be met at all times (CGPA and Pace of Progression minimum). If the student is on Academic Probation, a favorable or unfavorable appeal will count towards CGPA and Pace of Progression. If the student is on Academic Suspension and the appeal is favorable, then the grades earned during this period will count towards CGPA and Pace of Progression; however, if the appeal is unfavorable there will be no impact on CGPA and Pace of Progression.

<h5>Grade Appeal Policy</h5>

The grade appeal policy is to establish a clear, fair process by which students can contest a course grade that they believe has been awarded in a manner that is inconsistent.

The evaluation of student performance is based upon the professional judgment of the professors and appeals will not be considered unless based upon one or more of the following factors:

-   Grade computation error.
-   Standards different from those established in school policies.
-   The professor departed substantially from his or her previously articulated written standards, without notifying students, in determining the grade.

Only the final course grade may be appealed. The grade assigned by the professor is assumed to be correct and the student appealing the grade must justify the need for a change of the grade assigned.

The student should first reach out to the professor to try to resolve the grade issue.

If there is no satisfactory resolution, within 10 days of the conclusion of the course the student will send an email to the instructor, with a copy to the Dean, with an explanation of why he/she would like the grade formally reviewed. The student needs to be specific as to what issues he/she has
regarding the grade and instructor feedback on specific assignments.

If necessary, the instructor will be able to counter the argument as to why the grade should not be reviewed. The instructor will forward his/her counter argument to the Dean, with a copy to the student, for a decision.

The Dean may investigate the matter further by calling/emailing the student and/or the instructor. Once the Dean makes a decision, he will let the student and instructor know via email. Usually a decision will be made within seven

(7) business days but in the case of some courses, there may be a need for consulting a subject matter expert.

If the previous steps do not lead to a mutually agreeable resolution, and the student wishes to pursue the matter further, then the Academic Council shall be formed by the Dean within 10 business days. The Council’s decision may be to keep the assigned grade or to raise the assigned grade. The Council shall provide a written justification to the Dean for its decision, no later than 10 business days after the Council's formation.

The Dean shall inform all parties of the Council's decision.

This decision by the Council is final.

In the case of a change of grade, at any point of this review process, the professor must complete a "Student Grade Change Form" and submit to the Registrar within 10 business days after a decision is made.



<h5>Academic Council</h5>
The Academic Council is responsible for matters pertaining to CIU student academic performance and to disciplinary and corrective actions pertaining to student behavior, improper conduct, possible criminal issues or other such matters and for any faculty related issues.

The President of CIU has entrusted the Academic Council with the task of enforcing and interpreting CIU admission and academic policy. CIU outlines these policies in the school catalog and holds students responsible for adhering to them.

At the end of each term, the Office of the Registrar reviews final grades and student transcripts. If a student's record indicates he/she is experiencing academic difficulty, the Dean will be notified. The Dean will inform the student via e-mail, telephone or U.S mail of any change in status and recommends remedial steps for the student, imposes discipline such as probation on the student, or terminates the student. Students have the right to appeal the Dean's determination to the Academic Council. The Academic Council is the administrative body that reviews and either denies or grants a student's continuing enrollment in the school due to the student's academic standing or other issues related to CIU policies. The Academic Council is not limited to academic review.

The Academic Council makes recommendations to the President regarding student dismissal, suspension or other disciplinary actions, including those occasioned by academic performance, honesty and integrity, and behaviors that undermine the mission of CIU.

The student may invite faculty members or classmates to testify to matters of fact and may have legal counsel present. The Council may request the list of such individuals up to 10 days prior to the scheduled hearing if witnesses are desired. The Council reserves to limit the number of such individuals. The Council meets within the first two (2) weeks of the end of the term in which the Dean takes disciplinary action. However, any member of the Council can request an emergency meeting.

All recommendations from the Academic Council are forwarded to the President. The President shall consider the Council's recommendations in making the final determination and imposing disciplinary action.



<h5>Reinstatement Criteria</h5>
If the student seeks to return to CIU, he/she must file an application for reinstatement, presenting a plan that he/she is likely to succeed. The student must have had accumulated a minimum cumulative transfer GPA of at least 3.0, if courses were taken at another institution. These transfer courses are not calculated in the students' CGPA but will count towards the Pace of Progression. CIU is not obliged to grant reinstatement requests.


</div>

</div>

);}

export default GradingandAcademicProgress;