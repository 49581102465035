import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./GraduationRequirements.module.css"


const GraduationRequirments =()=>
{
    return(

 
      
      
      <div className={classes.content}>
            <div className={classes.text}>
          <h2>Graduation Requirements</h2>
                   
CIU grants an MBA degree to students who successfully complete the prescribed program credits and any related requirements. In order to be eligible for graduation, students must complete the program with a minimum cumulative grade point average of 3.0 ("B" average). The Registrar's Office maintains academic records of all course work completed at the school. A Diploma will be issued within sixty (60) days after degree conferral.



<h5>Capstone Project</h5>
The capstone project is an individual consulting project that must be completed in one of the final four (4) courses in a student's degree program with a presentation required on the last day of the selected course. Students select the course and opt out of the group consulting project for that course. The capstone substitutes for the group consulting component of that course and is graded by the faculty member teaching the course. The capstone is designed to highlight the student's individual capacity to perform a significant individual consulting project at the MBA level and thus complements the consulting projects completed in the other) courses. It is the responsibility of the student to acquire their own client for the capstone course. A student must plan with enough time to acquire a client and submit the capstone application form with required signatures by the end of their 8th course.

A student must submit the capstone application form with required signatures by the end of their eighth course.



<h5>Graduation & Commencement</h5>
There are important differences between graduation and commencement.

Graduation is a process that requires submission of an Application for Graduation, completion of all academic requirements for the intended degree, and settling all financial obligations. The administrative process will indicate the student's official date of graduation.

Commencement refers to the ceremony that takes place annually, officiated by the President of the School, where graduating students can celebrate their academic achievement with invited guests. This ceremony also gives the opportunity for the School, its administrators and faculty to acknowledge student accomplishments.



<h5>Conferral of Degrees</h5>
A degree becomes official when it is posted to the student's transcript. A student must complete a graduation application so that the Registrar's Office can verify graduation requirements completion and subsequently post the degree on the transcript. Degrees are awarded four times each year at CIU, on the first day after each calendar quarter (i.e. January 1st, April 1st, July 1st, October 1st; policy effective July 1, 2015). All diplomas, are prepared and mailed within sixty (60) days after degree conferral to the most current address provided by the student.

Students must apply for conferral of a graduate degree by filing an Application for Graduation during the term in which they expect to be awarded a degree. CIU, however, reserves the right to confer a degree on a student who has completed all of the requirements for a degree, even though the student has not applied to graduate.

Students who wish to withdraw a request for conferral or make changes to the Graduation Application should notify the Registrar's Office in writing by the deadline which is the 1st of the month prior to the degree conferral date (i.e. December 1st, March 1st, June 1st, and August 1st). Students who withdraw their graduation applications or fail
to meet degree requirements must reapply to graduate in a subsequent calendar quarter.

</div>
</div>

    );}
    export default GraduationRequirments;