import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./EnrollementandRegisteration.module.css"

const EnrollementandRegisteration =()=> {
    return(
       
      
      
      <div className={classes.content}>
         <div className={classes.text}>
                    <h2>Enrollement Process and Registeration</h2>
                   
<h5>Enrollment Process</h5>
Soon after the completed application package has been received by the Office of Admissions, if the prospective student has been accepted, he/she will receive an acceptance letter via email or mail. The Registrar will assist throughout the enrollment process. Essential documents, including transcripts for credit transfer, enrollment agreement, military service forms, and payment method, are collected by the admissions representative by email, fax, or regular mail.



<h5>Completing the Enrollment Process</h5>
The applicant will be given the Annual Campus Safety and Security Report to review, and also be informed of the location of this file in the Student Services office. The enrollment forms are provided in the following order: Distance Learning Questionnaire; Drug and Alcohol for a Drug-Free Workplace; School Performance Fact Sheet; Student Enrollment Agreement; Individual Degree Plan; Video and Picture Consent Form (Optional). The Registrar guides the applicant through the enrollment process, including explaining how to read the data in the School Performance Fact Sheet, and answering questions on the items and policies stated in the Enrollment Agreement and all other forms. Once the individual degree plan is reviewed and all required documents are submitted, and registration steps are completed, the enrollment process is concluded and the applicant officially becomes a student. First time CIU students will be scheduled for a required orientation onsite. The orientation gives students the opportunity to become comfortable with CIU's online learning management system: Canvas, go over the registration process and register for courses, take student ID pictures, and answer any questions students may have.



<h5>The process of enrollment includes completing the following tasks:</h5>
1.  Sign - The following forms:
    a.  Distance Learning Questionnaire
    b.  Drug and Alcohol for a Drug-Free Workplace
    c.  School Performance Fact Sheets
    d.  Student Enrollment Agreement.
    e.  Individual Degree Plan (included in the Enrollment Agreement).
    f.  Video and Picture Consent Form (optional).

2.  Welcome Letter (mailed with student ID)

3.  Pay - Tuition fees and other applicable fees.

4.  Attend - Student orientation.

Please see "Important Deadlines" for Student Orientation Dates.

Class Registration - Course Registration for current students opens up 6 weeks before the start of the term at 12:00 AM on that Monday. Students login in to their Student Portal (Populi) and go to the registration tab. Open spots for courses are available on a first-come first-served basis (as space allows). Registration for incoming new students occurs at Orientation.



<h5>Priority Registration</h5>
Priority Registration opens seven (7) days before the normal registration period. Priority registrations is only for students in their last two terms of the MBA program. This is to ensure their ability to register for the final courses they need to complete the program.



<h5>Tuition Payments</h5>
Every student must make tuition payment arrangements to complete the registration process by the published dates under "Important Deadlines" Students may not be accepted for enrollment if they cannot prove credit worthiness. The student must make financial arrangements ..
Please contact the Cashier's Office at (      )       -         or cashier@ciu.edu to complete payment terms.



<h5>Deferred Enrollment</h5>
Applicants who are admitted to CIU but decide not to register in the term for which they first apply may request deferral of their application for a later term within one (1) calendar year. In no case are application files retained for more than a year from the date of the initial application. Application after this period may be made only by completing a new application and providing all necessary documents, including the application fee. Admission is not guaranteed to previously admitted applicants who have an approved deferral. All deferral requests must be made in writing. Students who are denied admission must submit a new application and fee together with required documentation in order to be considered for admission in future terms.



<h5>Additional Admissions Requirements - International Students</h5>
1.  If your Bachelor's degree (in any field) was not from an accredited institution in the U.S., the following supporting documents are required for a proof of completion of the equivalent to a
U.S. Bachelor's degree from a recognized/accredited institution overseas with a 3.0 or higher GPA (on a 4.0 scale):

a.  A copy of all OFFICIAL academic transcripts in the original language from the issuing 
institution with official translations (by a certified translator overseas or in the U.S.)

b.  A official document-by-document credential evaluation report on the submitted transcripts from an evaluation service in the U.S. that includes a GPA calculation (Please view CIU’s Foreign Transcript Policy for instruction.).

2.  Pass an English Language Test (TOEFL, IELTS, PTE Academic, ITEP, or other English Language Tests accepted by CIU). *Your English score cannot be older than 2 years at the time that you submit it to CIU.

English language tests and required scores:
Insert Table 8 
<img src="Table8.PNG"/>
*Click the test name to proceed to the website.

As of July 2017, the TOEFL PBT test was discontinued and replaced by the revised TOEFL Paper-delivered Test. Though the TOEFL PBT test is no longer offered, test scores remain valid for up to 2 years after your test date. The revised TOEFL Paper-delivered Test will use the TOEFL iBT section score scale of 0-30. Because there will be no Total score, your evaluation of the applicant's English proficiency as indicated on the TOEFL test will be based on the 3 section scores (Reading, Listening and Writing).

If your test score cannot meet the English requirement for full admission, you may be granted a conditional admission to CIU. Conditional admission application will be reviewed and granted on a case-by case basis. Please refer to the chart above for acceptable* English Language Test Scores by CIU, and find more information in Conditional Admission-International Student section. CIU does not offer English language service or English as a Second Language instruction.



<h5>English Proficiency</h5>
English Requirement: International graduate applicants, who graduated from an institution in a non-English speaking country, must demonstrate English-language proficiency by taking the Test of English as a Foreign Language (TOEFL), International English Language Test System (IELTS); Pearson Test of English Academic (PTE Academic) or other CIU approved examinations (which first must be approved by CIU) in addition to our standard admissions requirements described above. All instruction occurs in English. English language proficiency is documented by:
    1.  The admissions review of English proficiency test scores and CIU Writing Assessment.
    2.  Receipt of prior education documentation as stated in the admission policy.



<h5>English Requirement:</h5>
<h5>International applicant can also meet CIU's English requirement by meeting one or more conditions below:</h5>
-   Attended a U.S. educational institution for 1 year or more and earned a Degree with a 2.0 GPA or higher.

-   Attended an institution in a country where the native language is English for 1 years or more and earned a Degree with a 2.0 GPA or higher.

-   Attended an institution for a year or more and earned a degree with a 2.0 GPA or higher where the official language of the institution was English for ALL coursework.

-   Attended and successfully completed a CIU approved Intensive English program in the U.S. Please contact Office of Admissions for an approved list of English programs.

-   Have taken a different English proficiency exam and achieved an equivalent score meets CIU's requirement. Please send your valid English score to Office of Admissions.

If none of above applies to you, you must complete the "English Requirement Form" request for an English exam waiver. Submit all supporting documents for the English waiver request (i.e. a degree official transcript, a professional certificate in the U.S., or a proof of completion in the CIU approved English program, proof of working experience in an English environment or a letter to explain why your English score requirement should be waived etc.)
All international applicants must complete online LLM application and pass the admissions interview and writing test (Admissions essay). The Office of Admissions will not approve or deny the English exam waiver request until all necessary documents have been received. You will be notified by the Office of Admissions on the result of your request. Your English Proficiency Exam may be also waived, if you are a citizen of the following countries.


</div>
</div>

    );}
    export default EnrollementandRegisteration;