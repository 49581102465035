import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./TuitionFees.module.css"
import Table10 from "../Resources/Table10.PNG"

const TuitionFees =()=>
{
    return (
       
       
      
      <div className={classes.content}>
          <h2>Tuition Fees</h2>
                   
          
          <img className={classes.image10}src= {Table10}/> 
          <div className={classes.text} >
            * Estimated charges for the period of attendance and the entire program. Prices are in USD.
At the student's option, the school may accept payment in full for tuition and fees, including any funds received through institutional loans, after the student has been accepted and enrolled with the date of the first class session disclosed on the enrollment agreement. The fee for a completed Try Before Buy course is at the current rate of tuition per course at the time of enrollment for the MBA program. There is a penalty of $25.00 per returned check. The application fee is reduced for military students. If a student is late on making tuition payments, a flat $50.00 fee will be added to the tuition payment and the student will not be enrolled in any courses until the tuition and fees are paid in full. Qualified CIU employees may receive institutionally funded tuition if admissions requirements are met. A Cost of Attendance (COA) breakdown for Title IV purposes is provided .
</div>
            



</div>
    )}

    export default TuitionFees;