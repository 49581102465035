import NavBar from "../Components/Navbar"

import classes from "./AcademicCalendar.module.css"


const ContactUs =() => {

  return (

 <div className={classes.gridcontainer}>
 
   </div>
 
 )}

export default ContactUs;


    

