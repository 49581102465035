import Footer from "./Components/Footer";
import NavBar from "./Components/Navbar";
import Network from "./Routes";




function App() {
  return (
    <div>
<NavBar/>
<Network/>
<Footer/>
    </div>
  );
}

export default App;
