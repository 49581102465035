import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./LearningPlatform.module.css"

const LearningPlatform =()=>{

return (

 
      
      
      <div className={classes.content}>
         <div className={classes.text}>
          <h2>Learning Platform</h2>
    
-Moodle is the world's most popular learning management system (LMS), used by countless schools, universities, not-for-profit organisations and companies to respond to their education and training needs.

-Moodle LMS delivers a powerful set of learner-centric tools to build a collaborative learning environment that empowers both teaching and learning.

-Moodle open-source platform is backed by a large community of developers and educators constantly looking for ways to enhance the online learning experience.


View California International University Courses on Moodle click <a href="https://moodlecloud.com/app/en/login">here</a>.

Process of enrollement in programs click <a href="https://docs.moodle.org/311/en/Course_enrolment#Add_students">here</a>.

Sign up for Moodle account click <a href="https://moodle.org/login/index.php">here</a>.



</div>

</div>




);}
export default LearningPlatform;