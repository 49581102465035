import React from 'react';
import { Navbar, Nav, NavItem, NavDropdown, NavLink } from 'react-bootstrap';
import "./navBar.css"

function NavBar() {
  return (
    
    <Navbar bg="dark" variant="dark" expand="lg" >
      <Navbar.Brand style={{marginLeft:"1%"}}href="/">California International University</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavItem>
            <NavLink href="/" activeClassName="active" >Home</NavLink>
          </NavItem>
          <NavDropdown title="Academics" activeClassName="active">
            <NavLink href="/academics/academic-calendar" >Academic Calendar</NavLink>
            <NavLink href="/academics/grading-and-academic-progress">Grading & Progress</NavLink>
            <NavLink href="/academics/programs">Programs</NavLink>
            <NavLink href="/academics/graduation-requirments">Graduation Requirements</NavLink>
            <NavLink href="/academics/student-services">Student Services</NavLink>
          </NavDropdown>
          <NavDropdown title="Admissions" activeClassName="active">
            <NavLink href="/admissions/admissions-requirments">Admission Requirements</NavLink>
            <NavLink href="/admissions/enrollement-and-registeration">Enrollment & Registration</NavLink>
            <NavLink href="/admissions/international-students">International Students</NavLink>
            <NavLink href="/admissions/admission-policies">Admission Policies</NavLink>
            <NavLink href="/admissions/financial-assistance">Financial Assistance</NavLink>
            <NavLink href="/admissions/tuition-fees">Tuition Fees</NavLink>
            <NavLink href="/admissions/tuition-refund-policy">Tuition Refund Policy</NavLink>
          </NavDropdown>
          <NavDropdown title="About Us" activeClassName="active">
            <NavLink href="/about-us/student-conduct">Student Conduct</NavLink>
            <NavLink href="/about-us/administrative-policies">Administrative Policies</NavLink>
            <NavLink href="/about-us/statements">Statements</NavLink>
            <NavLink href="/about-us/our-people">Our People</NavLink>
          </NavDropdown>
          <NavItem>
            <NavLink href="/contact-us" activeClassName="active">Contact Us</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/learning-platform" activeClassName="active">Learning Platform</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/apply-now" activeClassName="active">Apply Now</NavLink>
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
