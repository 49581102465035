import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./StudentConduct.module.css"

const StudentConduct =()=>
{
return(



  
  <div className={classes.content}>
            <div className={classes.text}>
      <h2>Student Conduct</h2>
                   
Students enrolled at CIU must demonstrate professionalism while at school and in their careers. Students are expected to abide by high standards of ethical conduct in preparing and presenting material that demonstrates their level of knowledge and that is used to determine grades.


The following are considered violations of acceptable student conduct and may result in dismissal:

-   Plagiarism and cheating are not accepted under any circumstances. CIU requires the use of APA citation in written assignments. For additional details please refer to CIU Canvas Orientation and Resources.

-   Use of alcoholic beverages or illegal drugs on campus.

-   Use of indecent or profane language (language or actions that disrupt the academic environment).

-   Failure to follow common sense rules of safety and/or posted safety regulations.

-   Harassment or discrimination of any kind.

-   Possession or use of a weapon of any kind Violation of any school policy or state and federal laws



<h5>Academic Honesty Policy</h5>
Honesty is the key characteristic of a successful leader. CIU prohibits dishonesty in connection with any academic activity at CIU, and though not within "Academic Policy", strongly discourages dishonesty in connection with any activity. Examples of dishonesty include but are not limited to, lying, cheating and stealing. Lying is defined as the deliberate act of being to be deliberately untruthful in order to gain an unfair advantage. Cheating is defined as the act of using or attempting to use unauthorized materials, information, ideas or work of another in order to gain an unfair advantage. Stealing is defined as the act of intentionally taking the property of another, without consent or permission and with the intent to keep or use the property without the permission of the owner.


If students are uncertain of this policy, they should consult their professor or administrator for clarification. All members of the academic community have a responsibility to ensure that academic honesty is maintained.
Examples of academic dishonesty include, but are not limited to:

-   Plagiarism.

-   Copying or sharing answers.

-   Presenting work done by others as one’s own.

-   Giving or receiving unauthorized assistance to or from another student.

-   Altering or falsifying any information.

-   Using any portion of material to fulfill the requirements of more than one course unless the student has received prior faculty permission to do so; Failing to adhere to a professor's specific directions with respect to the terms of academic integrity or academic honesty.

-   Falsifying information or record.

-   Any attempt to change grades or written records pertaining to assessment of a student's academic achievement.

-   Providing false or misleading information in order to be excused from classes or assignments.

-   Intentionally underperforming.

-   Unauthorized collaboration on items considered to be independent work.

-   Sabotaging work of other students, including hiding of shared resources.



<h5>Procedure</h5>
Before any formal action is taken against a student who is suspected of academic dishonesty, the professor must present clear evidence to the student in a private meeting or communicate with the student by some other means. The professor must initiate this communication with the student within 30 calendar days of discovering evidence of academic dishonesty. If the professor concludes that the student violated this policy, the matter may be resolved with the student through punitive grading. Examples of punitive grading are: assigning giving a lower or failing grade on the assignment, having; requiring the student repeat the assignment and perhaps some additional assignment; or assessing a lower or failing grade for the course.

Professors are encouraged to communicate with the Dean for adjudication and/or appropriate record keeping. If it is a repeat violation, the Dean may schedule with the involved professor(s) and student(s) a hearing with the Academic Council who will considers whether any further action should be taken which may include suspension or expulsion from CIU. The Council will consider the entire student record of misconduct when making its decision and it will not limit itself just to mere acts of academic dishonesty.

All grades are ultimately the responsibility of the professor. However, if a student accused of academic dishonesty wishes to contest a violation, the student may, within a 30 calendar day period, file a grievance to request a hearing from the Academic Council if he/she believes the violation is erroneous was a false accusation.

If a violation of academic dishonesty comes to the attention of an administrator, the administrator will bring the concern to the attention of the professor of the corresponding course.

Records relating to academic dishonesty will be maintained by the Office of the Dean.



<h5>Drug and Alcohol Policy</h5>
Zero tolerance policy on substance abuse: Possession of alcohol, drugs, or any indication of substance abuse will be grounds for immediate dismissal from CIU. Please see Student Handbook for more information on CIU's Drug and Alcohol Policy.



<h5>Lost or Stolen Personal Property</h5>
CIU is not responsible for lost or stolen personal property

-   valuables should not be left unattended on school grounds or facilities.

In addition, please lock your car doors and do not leave anything in your vehicle that would tempt somebody to break in. Remove valuables and avoid having valuable belongings in plain sight by securing them out of sight in your trunk or under a blanket.

<h5>Dress Code</h5>
All students will be expected to dress in an appropriate manner, to convey a professional appearance or image, and to be neat and clean. "Business Casual" is strongly encouraged.

Attending class or entering any campus building while barefooted or bare-chested is specifically prohibited.

Students & Professor Consulting Policy

Students and Professors are prohibited from doing paid consulting with a CIU client so long as the student or the professor is associated with CIU.

Students are also prohibited from doing CIU consulting for a professor or a professor's company. Students may not do a consulting project for an organization for which their professor consults or has previously an actual or potential conflict of interest nor in any situation where there is student a member of an organization or business. CIU might make
certain exceptions. However, the Dean must be notified ahead of time and approve it prior to any actions by the student team.

CIU students will NOT implement, or promise, or appear to promise the implementation of their recommendations or plans, either pro bono or for payment under any circumstances.

These policies are established to prevent any possible conflicts of interest or the appearance of such a conflict.

If a CIU client requests paid or unpaid work be done after the term consulting project is complete, the students and/or professor must decline and explain that work for hire for a consultation client is contrary to CIU policy.

After graduation or permanent separation from CIU, students and faculty are not restricted by CIU rules. However, students and faculty should be cognizant to avoid any unethical commitments or conflicts of interest that may reflect badly on them or on their alma mater.

<h5>Conduct Probation</h5>
Students may be placed on Conduct Probation should the following behaviors occur: disruptive or disrespectful behavior toward staff, faculty, or other students; theft of property, use of indecent or profane language, cheating and/or plagiarism in any form of work, repeated violations of the school dress code, harassment of instructors or other students, or discrimination of any kind. All conduct rules apply to the classrooms, school buildings, and parking areas. (Please see Suspension and Dismissal Policy.)

Anti-Hazing Policy

As stated in the State of California Education Code, Part 19,

Chapter 1, Article 5, Section 32050-32051:

"Hazing" includes any method of initiation or pre-initiation into a student organization or any pastime or amusement engaged in with respect to such an organization which causes, or is likely to cause, bodily danger, physical harm, or personal degradation or disgrace resulting in physical or mental harm, to any student or other person attending any school community college, college, university or other educational institution in this state; but the term "hazing" does not include customary athletic events or other similar contests or competitions.

The term hazing also has the following meaning as per the Higher Education Act of 1965: Any assumption of authority by a student whereby another student suffers or is exposed to any cruelty, intimidation, humiliation, embarrassment, hardship, or oppression, or is required to perform exercises to excess, to become sleep deprived, to commit dangerous activities, to curry favor from those in power, to submit to
physical assaults, to consume offensive foods or alcohol, or the threat of bodily harm or death, or the deprivation or abridgement or any right.

No student, member of the campus community or their guest at any public, private, parochial or military school, college or other educational institution, shall conspire to engage in hazing, participate in hazing, or commit any act that causes, or is likely to cause, bodily danger, physical harm, or personal degradation or disgrace resulting in physical or mental harm, to any student or member of the campus community.

The violation of this section is a misdemeanor, punishable by a fine of not less than one hundred dollars ($100), not more than five thousand dollars ($5,000), or imprisonment in the county jail for not more than one year, or both. In cases of accidents, injury, death or dismemberment as results of hazing, other civil and/or criminal charges and punishments may be applied accordingly as per the appropriate authorities.

Any individuals found guilty of violation of this section of this Policies shall be subject to appropriate disciplinary action by CIU.

If a group or individual is suspected or reported to be hazing, a thorough investigation will be conducted by CIU. While the investigation takes place, the suspected individual may be suspended.

The names of individual students suspected of hazing will be turned over to the Administration for investigation and/or for the disciplinary action.

</div>
</div>

);}
export default StudentConduct;