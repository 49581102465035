import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./FinancialAssistance.module.css"


const FinancialAssistance =()=>{
    return (

    
      
      
      <div className={classes.content}>
         <div className={classes.text}>
          <h2>Financial Assistance</h2>
                   
The Federal Military Tuition Assistance Program

The Tuition Assistance program provides educational assistance to active duty military members. Each branch has specific tuition assistance limits and application procedures.

When your Tuition Assistance Authorization form is received, please submit a copy to the CIU Admissions Office at least two (2) weeks before your class start date. If you're eligible for the Montgomery GI Bill (MGIB) and plan to use Tuition Assistance, you can use the MGIB Top-Up Benefit to cover the remaining costs of your college.



<h5>Payment Plan</h5>
For those who do not utilize financial assistance, CIU offers an institutional payment plan that requires no interest and is designed to be fully completed by graduation. Additional information may be obtained through the Admissions Office at admissions@ciu.edu.



<h5>Scholarships</h5>
<h5>Application Requirements</h5>
Any new student is eligible to apply. Students must have applied and satisfied all of the following 3 criteria, the criteria outlined for each scholarship, and meet the published deadlines to qualify. Only one (1) scholarship per student will be awarded and it is non-renewable.

-   Students must have been accepted into CIU
-   Must meet SAP and maintain a 3.0 CGPA throughout the program
-   Be enrolled in at least one (1) course per term

Deadline for scholarship application in the 2020-2021 

*In the 2020-2021 school year (March 21, 2020 - March 05, 2021), CIU will make $2,000 available for scholarships to new students who meet eligibility.

Scholarship applications will be reviewed in August 2020 and students who are awarded a scholarship will have the
first payment applied for the Fall 2018 Term. The second payment is applied during the student's 10th course.



<h5>Disclosures</h5>
-   Recipients are responsible for all other educational expenses

-   Awards are disbursed in 2 increments, the course after the scholarship is awarded and the 10th course. If a student receives the first payment and fails to maintain a GPA of 3.0 at CIU, then they will forfeit the second payment (the 10th course payment).

-   Must be a United States citizen, or Permanent Resident for the Community Scholarship.

-   The first scholarship payment must be issued within the same academic year that the scholarship was awarded, otherwise the entire scholarship will be forfeited.

-   To retain scholarship eligibility, recipients must remain in good academic standing (3.0 CGPA).

-   To qualify for a scholarship, students must maintain continuous enrollment on a term basis.

-   Students can apply for as many scholarships as they are eligible, but will only be awarded a maximum of one (1) scholarship for the degree.

-   Students who receive a scholarship are not eligible for a Fellowship.




</div>
</div>
    )}
    export default FinancialAssistance;