import Footer from "../Components/Footer"
import NavBar from "../Components/Navbar"
import Table11 from "../Resources/Table11.PNG"
import Table12 from "../Resources/Table12.PNG"
import Table13 from "../Resources/Table13.PNG"
import classes from "./AcademicCalendar.module.css"


const AcademicCalendar =() => {

  return (


 
 
 <div className={classes.content}>
 
     
 <h2>LLM IN LAW Academic Calendar 2020-2021</h2>
<div className={classes.pic11}>

</div>                            


<h5>DIAGNOSTIC MEDICAL SONOGRAPHER Academic Calendar 2020 - 2021</h5>
<div className={classes.pic12}>

</div>
<div className={classes.text}>
*Payments due by 11:59pm Pacific Standard Time.

*Dates and times in the Academic Calendar may be subject to change.

All on ground courses are held in Suite 10402


LLM Program:

Last day to Add/Drop (Cancellation Period). Fourteen (14) calendar days after the term start date.
Last day to Withdraw with a grade of "W" before the end of the eighth week.


MDS Program:

Last day to Add/ Drop (Cancellation Period) Seven (7) days after the term start date.
Last day to Withdraw with a grade of "W" before the end of the fourth week.

</div>

<h5>Holiday Schedule</h5>

Please note that our Offices will be CLOSED on the following holidays:
<div className={classes.pic13}>

</div>

<div className={classes.text}>
<h5>Office Hours</h5>
Our office hours are from 8:30 AM to 5:30 PM, Monday through Friday. CIC offices are closed on Federal Holidays as shown above.



<h5>Religious Holidays</h5>
CIU accommodates students and faculty with religious affiliations who wish to celebrate their religious holidays provided proper arrangements are made in advance. Faculty must arrange a substitute professor for the day of the holiday required. Students also are excused from class on a holiday requiring their absence, but are required to make special arrangements in advance of the class to make up the coursework with their professor. At least two (2) weeks advance notice to the professor is required to avoid being penalized for an absence.
</div>


</div>
)}

export default AcademicCalendar;


    

