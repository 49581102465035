import React from 'react';
import "./Home.css"
import backgroundImage from "../Resources/ggb.jpg"; // Replace with your image path
import overlayImage from '../Resources/w1.png'; // Replace with your image path

const Home= () => {
  return(
    <>    <div className="background-image" > <div className="overlay-image" ></div> </div> 
        </>

  )
}

  



export default Home;