import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./StudentServices.module.css"

const StudentServices =()=>
{
    return (

     
      
      
      <div className={classes.content}>
            <div className={classes.text}>
          <h2>Student Services</h2>
                        
<h5>New Student Orientation</h5>
Orientation is required for all new students. There is an on-ground and online component through Canvas (LMS). Various administrators will guide students through their program. The Student Success Team informs the students about the specifics of our program, including but not limited to our specially developed teaching model and the process of developing a consulting report. Student Success will also provide the students with some tips and tools for adult learning. Other administrators will be sharing information such as correct APA citation, the student learning platform (Canvas), library resources, academic calendar, staff directory, and student services.



<h5>Academic Advisement</h5>
Through electronic correspondence, telephone, individual appointments or drop-ins, the of Student Success Team are available during normal office hours and class sessions to assist students in need of academic advising.



<h5>Transcript Services</h5>
CIU understand the importance of providing our students with effective and efficient transcript services. Your new career, entrance to graduate school, professional certification, or other important events depend on the prompt and secure delivery of your transcript and we want to keep you informed of order and delivery status. We also understand the need to protect the privacy of your transcript. The instructions that follow will enable you to request your transcript for delivery as quickly and efficiently as possible.

Transcripts: The transcript is the official record of the academic performance of the student at CIU Students may acquire either an unofficial transcript (which may be used to informally see classwork or grades) or an official transcript, which is the official document recording the students' academic record at the school. Official and Unofficial transcripts of CIU coursework (grades) are available approximately four (4) weeks after the completion of courses. It can take an additional 3-4 weeks to confer the degree.

Unofficial Transcripts: Registered CIU students, as well as former students, may request an unofficial transcript from the Registrar's Office at CIU. Unofficial transcripts can be printed electronically by the student through the student portal, Populi, or can be ordered in person, by email, mail or fax. An unofficial transcript lists all of a student's coursework but does not include the school seal or signature of the
registrar. Unofficial transcripts can only be issued to students and cannot be released to a third party.

Official Transcripts: The first official transcript that includes a conferred degree will be issued upon the completion of the program and the Dean's approval. A student can order up to five (5) additional Official Transcripts at the Registrar's Office per day, and 30 in a 12-month period at CIU. Normal processing time, excluding delivery, is 3-5 business days from the date the signed request is received.

Ordering Transcripts: Official transcripts can be ordered electronically by the student through the student portal, Populi, or can be ordered in person, email, mail or fax. Orders are not accepted by telephone. Requests will be sent out via U.S. mail or can be picked up in the Office of the Registrar at CIU. If someone other than yourself will be picking-up the transcript, you need to provide a signed release authorizing the third party to pick-up your official transcript. In accordance with CIU policy, as well as with state and federal privacy laws, a student's signature is required for release of the academic transcript, either official or unofficial. All requests must have the student's signature.

Fees: Upon conferral of the degree, students are provided with a copy of their official transcript and their diploma at no cost. Students and alumni requesting additional copies of their official transcript and/ or diploma pay a $10 fee for each official transcript and/or $50 for each diploma. The full fee is payable by cash, credit card or check made out to CIU

<h5>Transcript Policy</h5>
It is the student’s responsibility to clear all holds on his or her account before submitting a transcript request. Any hold, such as an outstanding financial balance, will delay or prevent a request from being processed. If you have questions regarding any hold(s) on your record which would delay processing your transcript, please contact CIU at    , or email registrar@ciu.edu.

Processing time is the time it takes our office to prepare your transcript. This does not include mailing time. We cannot guarantee your transcript's arrival or the time it will take to reach its destination once it has left our campus. All coursework will show on a student’s CIU transcript, including transfer credit(s). For security purposes, we do not fax out transmit transcripts. CIU issues official transcripts by mail or in person.

Every transcript is checked for accuracy. It is the student's responsibility to direct concerns and/ or discrepancies to the Registrar's Office within 90 days of the transcript request.
Transcripts for pick-up will be held for up to two (2) months by the Registrar's Office, after which they will be destroyed. Students must then place a new transcript order and pay all applicable fees. CIU does not offer a notary service.

Transcript fees are non-refundable. Once a transcript request has been submitted it cannot be cancelled or changed.

<h5>Transcripts/Credentials from Other Institutions</h5>
All transcripts and credentials received (for admission, transfer articulation, etc.) become the permanent property of CIU and cannot be returned to the student or forwarded to other institutions; once received these documents are retained by the Registrar’s Office. Federal policy states that a student has the right to view these documents in his or her file. However, CIU does not release copies of transcripts from other institutions. Those transcripts must be requested directly and are subject to the rules and regulations of that institution.

<h5>Student Workshops or Seminars</h5>

Student seminars will be offered throughout the year, topics include job finding, developing effective writing skills, leadership development, etc.

<h5>Volunteer Opportunities</h5>

Students have opportunities to develop and expand their skills through volunteering to assist faculty or administrators in various school departments such as admissions, administrations, marketing, and information technology.

<h5>Non-Federal Work-Study (NFWS) Program</h5>

The Non-Federal Work-Study Program (NFWS) consists of funds granted solely by CIU to aid enrolled students in achieving a graduate degree education. The program is also intended to attract high-quality, full-time graduate students and provide them with professional experiences while at CIU. This program offers selected and qualified students up to a 15 hour per week work schedule in various administrative offices. The students can use the earned wages to pay for a portion of their tuition at CIU. Students must maintain a satisfactory grade point average and job performance for continued employment for the duration of the position assignment. An agreement specifying job performance criteria and academic criteria will be signed by the student at the time of hire. The students will be provided with meaningful learning experiences that help to meet their educational and/ or career goals, without displacing or replacing regular employees. Please visit our website for a current list of open positions. NFWS is only available to students who have successfully completed at least 1 (one) term at CIU. Applications for this program are accepted year-round. Any CIU new or continuing student in good standing is eligible to apply for this program. Applicants will be interviewed by the hiring department head with questions based on the cover letter and application.
Additional selection criteria will consist of the student's GPA, work experience, presentation skills, writing skills and other accomplishments. Students meeting criteria will be accepted on a first-come, first-serve basis until available positions are filled. (The availability of NFWS positions varies throughout the year; the maximum at any given time is three

(3). Candidates must present proof of eligibility to work in the United States prior to consideration. Students interested in NFWS are eligible to receive a maximum of $10,000(which may be subject to tax withholdings) for a total maximum of 667 hours for the total program. Length of appointment and hourly wage depends on position. There are no additional benefits provided by CIU. Students are paid hourly and timesheets are submitted to Payroll monthly. All students are encouraged to apply for open positions if position requirements are met. Please contact the Office of Admissions for more information.

<h5>Career Services</h5>

CIU's teaching model integrates career development, such as job search, into our academic program. Our teaching model also incorporates consulting in each course, showcasing our MBA student’s abilities to potential employers. There are also visiting executives in each course.

CIU is committed to working closely with students and graduates on their professional development, both inside and outside of the classroom, in order to help them achieve their career goals. In addition to working closely with current students and graduates, CIU is also committed to building lasting relationships with potential employers in order to create opportunities for CIU graduates as well as serve the needs of potential employers.

The purpose of CIU's Career Services Program is to assist CIU's graduates who request for employment in finding appropriate positions in line with CIU’s MBA degree in Executive Management and Entrepreneurship. The Career Services Department will assist CIU's graduates in the following areas:

-   Preparing students to compete for employment opportunities through: Interviewing skills & follow up; Networking; Resume writing; Application completion; Cover letter writing; Professional behavior and attire coaching; Setting up interviews; Keeping a career log; and establishing a social media profile.

-   Developing meaningful and sustainable relationships with employers

-   Assisting graduates throughout the entire hiring cycle.



<h5>Writing Composition Services</h5>
CIU considers excellent written and oral communication in English to be essential for students and graduates. Every course requires that students perform at a graduate level in tasks that demand these skills in order to obtain a passing grade.

To ensure student success, admissions screening includes a series of oral interviews and the completion on site of an Admissions Essay. Admission is contingent on adequate performance on these tasks.

Students who are advised that their writing assignments are not up to CIU standards are offered the option of writing composition tutorials on an as needed basis.

These tutorials may be face-to-face, or conducted via email. Group sessions will be organized if a sufficient need is identified by faculty and CIU administrators.

For individual writing composition assistance, students may contact student.success@ciu.edu or to schedule an appointment.


<h5>Writing Assistance</h5>
A graduate of the CIU LLM program should be capable of communicating in written English such that they can produce documents (formal proposals, contracts, policy documents, and even emails) that are clear, professional, and free from grammatical errors. An effective document is supported by comprehensive research to support observations and arguments. While CIU has a written admission essay requirement, English is "acquired" as well as "learned", and this process takes time. CIU recognizes that mastery of the written English language requires practice and appropriate feedback. Each of the twelve courses in the LLM program has numerous written assignments to provide the opportunity for all students to practice and improve their written English.

To assist with the feedback component, CIU provides English Composition Consultants to assist students who want or require individual, custom tutoring to continue their progression to master the written English language to achieve the level of a business professional. Access to the consultants is available at tutor@ciu.edu. Faculty may encourage (or even require) written assignments to be reviewed by the consultants when they evaluate a written assignment. This service is offered at no additional cost to all registered students in the CIU LLM program.



<h5>Presentation Assistance</h5>
A graduate of the CIU LLM program should be capable of communicating orally and visually such that they can present professionally in person, on video or teleconference and produce formal presentation decks that are clear, professional,

To assist with this feedback component, CIU provides Presentation Consultants to assist students who want or require individual, custom tutoring to continue their progression to master presentation skills to achieve the level of a business professional. Access to the consultants is available at presentations@ciu.edu. Faculty may encourage (or even require) presentations to be reviewed by the consultants when they evaluate a presentation. This service is offered at no additional cost to all registered students in the CIU LLM program.



<h5>Course & Class Auditing</h5>
CIU alumni have the opportunity to update their knowledge of content covered in a course they have already taken by auditing that same course for free, when space allows. The student must complete the Auditing application form to reserve a spot with Admissions.

CIU welcomes prospective students to sit-in on a number of classes in a course after completing the appropriate application process. The general public is invited to sit in on

occasional classes and to attend classes featuring guest speakers. However, those visitors who have not completed the application process have limited access to classes depending on space availability and other factors. Visitors in this category must reserve space in prior to attending. For reservations, call (626) 350-1500.

</div>
</div>
    );}

    export default StudentServices;