import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import classes from "./AdmissionPolicies.module.css"

const AdmissionPolicies =()=>{
    return (

       
      <div className={classes.content}> 
      <h3>Admission Policies</h3> 
      <div className={classes.text}>
Students will be admitted to the institution without regard to race, creed, color, ethnicity, religion, background, native origin, physical disability, or sexual orientation. Any students or prospective students who feel they have been a victim of discrimination should immediately report it to the Dean. The Dean will conduct an investigation and will carry out any disciplinary action deemed appropriate. In addition, CIU maintains and follows a strict policy prohibiting sexual harassment, in any form, including verbal, nonverbal, physical, visual conduct and/or reprisal.

Orientation is given to new students before the start of the first class where important school policies and procedures will be discussed. Students will sign an acknowledgment for their file indicating that they have received all pertinent information, including specific directions for accessing the most current CIU school catalog online at www.CIU.edu. An electronic copy (pdf) of the school catalog will also be
emailed to the student prior to enrollment. Printed catalogs are available upon request.

Transcripts received by CIU become the property of CIU and will not be released or returned to the applicant or forwarded to any other institution.
</div>


<h5>Articulation Agreements</h5>
CIU has an articulation agreement with    . Under this agreement, qualified graduates are eligible for admission to the programs at CIU.



<h5>Transferring Credits</h5>
Students wishing to transfer credits to CIU from another institution must request that an official transcript from the issuing institution(s) be provided directly to CIU for evaluation.


<div className={classes.text}>
<h4>Transferring Credits Policy</h4>

<h5>At the minimum, transfer of credit from one institution to another involves at least three (3) considerations:</h5>
<ol>
<li>The educational quality of the learning experience which the student transfers;</li>

<li>The comparability of the nature, content, and level of the learning experience to that offered by the receiving institution.</li>

<li>The appropriateness and applicability of the learning experience to the programs offered by the receiving institution.</li>
</ol>
Course credit is granted for graduate courses satisfactorily completed with a grade of "B" or better at accredited institutions when such courses are no older than seven (7) years and cover the same material or equivalent material as one or more courses in the CIU program. A maximum of two (2) courses, six (6) semester units or equivalent may be transferred.

CIU will charge a $25.00 flat, non-refundable fee for transfer credits. Be aware that transcript evaluation may take several weeks or more after receiving official transcript(s) and completed request form. Transfer credit is not counted towards a student's Cumulative Grade Point Average (CGPA), but is counted towards the "Pace of Progression" and the maximum time frame allowed for completion of the program. Please see "Satisfactory Academic Progress" for more information.

If the student is dissatisfied with the number of transfer credits awarded, he/she can appeal to the Academic Council. The Academic Council will respond to the student within 10 working days. The request/complaint must be made within two (2) weeks of the student receiving approval of the transfer credit. CIU does not award any credit for prior experiential learning, challenge examinations or achievement tests.

</div>

<h5>Transfer Credit Evaluation</h5>
As soon as the prospective student has completed the application requirements and submit a Transfer Credit Request Form, the information is sent to the Dean for transfer credit evaluation. An unofficial transcript, which may be sent from the applicant to CIU, is accepted to expedite the credit evaluation. An official transcript, which must be sent to CIU directly from the institution or military branch where the credit was earned, must be submitted along with the application package. An assessment is generated listing all course work that is transferable. The assessment is used to generate the Individual Degree Plan (IDP).



<h5>English Proficiency</h5>
The CIU programs are taught in the English language. Therefore, it is imperative that the student have adequate language skills. CIU assesses the English proficiency of students by requiring that they complete a timed Admissions Essay. The essay is evaluated by an administrator trained in assessing student writing and experienced in applying academic standards and performance expectations. Students must be able to read and understand English at a level equivalent to that of a graduate of an American 4-year college.


<div className={classes.text}>
<h5>Technical Minimum Requirements for the CIU Programs</h5>
The computer a student will utilize for the CIU programs must meet the minimum requirements below. Note that the minimum computer and software requirements may evolve during a student's course of studies, in particular as third-party vendors discontinue support for older versions of the product.

<h5>Minimum Hardware and Operating System</h5>
<ol>
<li>  Intel Core i3-3xxx or greater.</li>

<li>  4GB RAM or more & 20 GB of free hard drive space or more.</li>

<li>  If running Windows, Windows 10 or greater.</li>

<li>  If running OSX, OSC 10.10 or greater.</li>

<li>  Webcam, sound card, microphone & speakers set up on your computer, or a smartphone or tablet that has video and sound recording capabilities.</li>

<li> USB Flash Drive: Minimum Storage of 8GB recommended</li>
</ol>
</div>

<div className={classes.text}>
<h5>Software</h5>
<ol>
<li>  Internet Browser: Google Chrome, Internet Explorer, or Firefox with Adobe Flash Player with most current update.</li>

<li>  Microsoft Office 2010 or higher: Word, Excel, PowerPoint (or equivalent such Mac iWork), rtf.</li>

<li> Current version of Adobe Reader</li>

<li>  USB Flash Drive: Strongly recommended (1 GB+)</li>

<li>  Video Media Player (such as Windows Media Player or QuickTime Player (or equivalent), which are available free of charge from the manufacturers.</li>
</ol>
</div>

<div className={classes.text}>
<h5>Internet Connection</h5>
<ol>
<li>  A reliable broadband Internet connection, either cable or DSL of at least 5Mbps download speed (constant) for adequate audio video quality.</li>

<li>  An email address that will accept all emails, including attachments, from the domain name CIU.edu.</li>
</ol>
Note: Students are presumed to receive the messages sent to designated CIU.edu email addresses. It is the responsibility of the student to ensure that messages from CIU are not blocked and that the mailbox is not too full to receive messages.
</div>

</div>


                    
                
    );}

    export default AdmissionPolicies;