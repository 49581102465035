import classes from "./AdmissionRequirements.module.css";
import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";

const AdmissionsRequirments =()=> 
{
    return(

     
      
      <div className={classes.content}>
         <div className={classes.text}>
    <h2>Admissions Requirements</h2>

   
The following are admissions requirements for the MBA program at CIU:
<ol>
<li> For LLM :A Bachelor's degree in any field from an accredited institution with a 3.0 or higher GPA.</li>
*   An accredited institution is one that is recognized by the United States Department of Education (USDE) or the Council for Higher Education Accreditation (CHEA).
*   For any other program : High school diploma or ecouvelant certificate.

<li>  Minimum one year of work experience.</li>

<li>  Successful completion of the CIU Admissions Essay.</li>
</ol>
(Additional requirements may apply, please see below for details)

Note: Candidates not meeting these eligibility requirements may petition the Dean to request a waiver with requirements one (1) and two (2) above. The Academic Council may be involved for evaluation and recommendation and if required. Waivers may be granted based on exceptional circumstances such as: having to work while attending school, exceptional accomplishments as a student or post-graduate, hospitalization for exceptional periods, military service, etc. A selection committee reviews all information supporting admission criteria for all applicants. Meeting basic admissions criteria does not guarantee acceptance. Should a student be notified of denial by Admissions, the student is eligible to reapply after six months from their initial application.


 <h5>Before You Apply:</h5>
Review - Class Schedule and School Catalog.

Submit Application Package:
<ol>
<li>  Complete the Online Application Form:</li>

    -   Current Resume.
    -   Copy of Driver's License or Passport.

    <li>  Pay application fee:</li>

    a.  Contact the Cashier's Office cashier@ciu.edu or 1(    )    -     for payment
    b.  Submit payment within the online application form 

    <li>  Submit Official Transcript(s)*</li>

    <li> If interested in transferring credits - contact the Office of Admissions at admissions@ciu.edu.</li>
</ol>
Note: An incomplete admissions application package will not be processed and evaluated. Once your application package has been completed and requirements are met, the Office of Admissions will contact you about the next steps in the admissions process.


<h5>Submitting Transcripts for Admissions</h5>
When applying to CIU, each applicant is required to submit official transcripts of their Bachelor’s Degree or higher. Transcripts can be electronic or paper documents. Electronic Transcripts are official documents sent from your institution securely through the internet (i.e. via escrip-safe). To be considered “official”, paper transcripts must remain sealed as they were by the issuing university. Transcripts from a US university, must be sent directly to CIU from the issuing institution. The official transcript must include the complete official academic record (showing all course titles, dates, taken, and grades received) and the title of any diploma or degree awarded. Copies of the diploma or degree only are not sufficient. Scanned, photo-copied, or faxed copies of transcripts are NOT considered official.

*Transcripts: If you obtained your undergraduate degree outside of the United States, please refer to the "Foreign Transcripts" section under "Additional Admissions Information - International Students."


<h5>Next Steps in the Admissions Process</h5>
Schedule with the Office of Admissions:
<ol>
    <li>  Admissions Interview </li> 
    <li>   Admissions Essay. </li> 
    </ol>
<h5>CIU Admissions Essay</h5>
CIU assesses the English proficiency of students by requiring that they complete a timed Admissions Essay. The essay is evaluated by a CIU administrator trained in assessing student writing and experienced in applying academic standards and performance expectations.

In the event that the Admissions Essay needs to be taken online, an admissions representative will schedule a one (1) hour timeslot with the student. At the appointed time, the admissions representative will set up a video conference call with the prospective student to verify their identity and to go over the rules of the Assessment Writing Test. Once their identity has been verified and the student is ready, the test will be sent via email and the student will have one (1) hour to complete the test, sign it, and return it via email to the admissions representative in a timely manner.


<h5>Admissions Interview</h5>
After you have submitted your Application Package and have completed the Admissions Essay, you will schedule an interview to meet with the Office of Admissions. This can be conducted on campus or online.

</div>
</div>


           
);}
export default AdmissionsRequirments;
